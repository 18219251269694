import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  responseContainer: {
    display: "flex",
    marginBottom: 16,
    fontWeight: 700,
  },
  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: "20px",
  },
  rightContainer: {
    flex: 1,
    wordWrap: "anywhere",
    color: "#1D2023",
    "& h6": {
      marginBottom: 16,
    },
  },
  url: {
    color: "#EA891D",
  },
});

const UrlDisplay = (props) => {
  const classes = useStyles();
  const { requestUrl } = props;

  return (
    <>
      <div className={classes.responseContainer}>
        <div className={classes.responseCode}>Curl</div>
        <div className={classes.rightContainer}>
          curl -X GET
          <br />"<span className={classes.url}>{requestUrl}</span>" &nbsp; -H
          accept: application/json
        </div>
      </div>
      <div className={classes.responseContainer}>
        <div className={classes.responseCode}>Request Url</div>
        <div className={classes.rightContainer}>
          <span className={classes.url}>{requestUrl}</span>
        </div>
      </div>
    </>
  );
};
export default UrlDisplay;
