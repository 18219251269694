import {
  Button,
  FilledInput,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { store } from "../contexts/store";
import { setMode, setFeature, setPolygon } from "../contexts/actions";

import debounce from "lodash.debounce";
import { domain, api_version } from "../config";
import Disclaimer from "./Disclaimer";
import ResponseDisplay from "./ResponseDisplay";
import UrlDisplay from "./UrlDisplay";
import InputField from "./InputField";

const useStyles = makeStyles({
  info: {
    padding: "24px 20px",
    boxSizing: "border-box",
    color: "#949EA7",
    backgroundColor: "#fff",
    marginBottom: 8,
    "& h3": {
      lineHeight: "24px",
      marginBottom: 8,
      color: "#1D2023",
    },
    "& h4": {
      color: "#1D2023",
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 600,
      margin: 0,
    },
    "& h6": {
      color: "#1D2023",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
      margin: 0,
    },
  },
  iconRoot: {
    fill: "none",
  },

  mt8: {
    marginTop: 8,
  },

  flex: {
    display: "flex",
  },
  flex1: {
    flex: 1,
    wordWrap: "anywhere",
  },
  rightContainer: {
    flex: 1,
    wordWrap: "anywhere",
    color: "#1D2023",
    "& h6": {
      marginBottom: 16,
    },
  },
  responseContainer: {
    display: "flex",
    marginBottom: 16,
    fontWeight: 700,
    maxWidth: "100%",
    marginTop: 16,
  },
  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: "20px",
  },
  inputRoot: {
    borderRadius: 4,
  },
  generateResp: {
    marginTop: 16,
    textTransform: "capitalize",
  },

  textFields: {
    display: "flex",
    marginTop: 12,
  },
  shrinkLabel: {
    fontSize: 14,
  },
  radioGroupRoot: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
  },
  typeOfBiling: {
    fontSize: 16,
    marginRight: 32,
    color: "#1D2023",
    width: 118,
  },
});

const BBoxDrawer = (props) => {
  const classes = useStyles();

  const {
    state: { mode: mapDrawMode, polygon, feature },
    dispatch,
  } = useContext(store);

  const [bBoxPoints, setBBoxPoints] = useState([]);
  const [nextCursor, setNextCursor] = useState("");

  useEffect(() => {
    if (polygon && polygon[0] && polygon[0].length) {
      let maxCallback = (max, cur) =>
        Math.max(parseFloat(max), parseFloat(cur));
      let minCallback = (max, cur) =>
        Math.min(parseFloat(max), parseFloat(cur));

      const minLng = polygon[0]
        .map((point) => point[0])
        .reduce(minCallback, Infinity);
      const maxLng = polygon[0]
        .map((point) => point[0])
        .reduce(maxCallback, -Infinity);

      const minLat = polygon[0]
        .map((point) => point[1])
        .reduce(minCallback, Infinity);
      const maxLat = polygon[0]
        .map((point) => point[1])
        .reduce(maxCallback, -Infinity);

      setBBoxPoints([minLng, minLat, maxLng, maxLat]);
    }
  }, [polygon]);

  const comparePolygonArrays = (array1, array2) => {
    return array1.every(
      (value, index) =>
        value[0] == array2[index][0] && value[1] == array2[index][1]
    );
  };

  const baseUrl = `${domain}/${api_version}`;
  // "https://2aqfsaq392.execute-api.eu-central-1.amazonaws.com/production";
  // "https://api.digifarm.io/v1";

  const [responseJson, setResponseJson] = useState();
  const [responseStatus, setResponseStatus] = useState();
  const [requestUrl, setRequestUrl] = useState();
  const defaultToken = "a0731a8c-5259-4c68-af3a-7ad4f6d53faa";
  const [token, setToken] = useState(defaultToken);
  const [billing, setBilling] = useState("by_field");
  const [limit, setLimit] = useState(300);
  const [version, setVersion] = useState("latest");

  const handleClick = () => {
    const nextCursorParam =
      api_version === "development" && nextCursor
        ? `&cursor=${nextCursor}`
        : "";
    const limitParam =
      api_version === "development" && limit ? `&limit=${limit}` : "";
    const fetchUrl =
      baseUrl +
      "/delineated-fields" +
      "?token=" +
      token +
      "&bbox=" +
      bBoxPoints.join(",") +
      "&data_version=" +
      version +
      "&billing=" +
      billing +
      nextCursorParam +
      limitParam;

    const rectangle = [
      [
        [bBoxPoints[0], bBoxPoints[3]],
        [bBoxPoints[2], bBoxPoints[3]],
        [bBoxPoints[2], bBoxPoints[1]],
        [bBoxPoints[0], bBoxPoints[1]],
        [bBoxPoints[0], bBoxPoints[3]],
      ],
    ];

    if (polygon && polygon.length) {
      if (!comparePolygonArrays(rectangle[0], polygon[0])) {
        dispatch({
          type: setPolygon,
          payload: {
            polygon: rectangle,
          },
        });
      }
    }

    setRequestUrl(fetchUrl);
    console.log(fetchUrl);
    fetch(fetchUrl)
      .then((response) => {
        setResponseStatus(response.status);
        return response.json();
      })
      .then((data) => {
        setResponseJson(data);
        if (api_version === "development") {
          // nextCursor
          // If next cursor is present then take the previous feature and then add the current set of features to it.
          // The previous data is a feature collection, the current one will be too.
          // let newFeatures =

          dispatch({
            type: setFeature,
            payload: {
              feature: nextCursor
                ? {
                    type: "FeatureCollection",
                    features: [...feature.features, ...data.geojson.features],
                  }
                : data.geojson,
            },
          });
          data.next_cursor
            ? setNextCursor(data.next_cursor)
            : setNextCursor("");
        } else {
          dispatch({
            type: setFeature,
            payload: {
              feature: data,
            },
          });
        }
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h4>/delineated-fields</h4>
        <p>
          {api_version === "development"
            ? "Returns GeoJSON of delineated fields in the given bbox. Returned GeoJSON features will be limited to max 300 features or 6 Megabytes. If there are more polygons that match the query than the ones already recieved in the response, next_cursor value present in the response can be used to query the next set of fields by passing it as the value of cursor parameter"
            : "Returns GeoJSON of delineated fields in the given bbox. Returned GeoJSON features will be limited to max 1000 features. In case you get 1000 features, split the querying bbox further to get complete features."}
        </p>
        <h3 className="mb-16">Parameters</h3>
        <InputField
          label="Client Token provided by Digifarm and EarthDaily Analytics"
          defaultValue=""
          value={token}
          onChange={(e) => {
            setToken(e.target.value);
          }}
        ></InputField>
        <div className={classes.radioGroupRoot}>
          <div className={classes.typeOfBiling}>Type of Billing :</div>
          <RadioGroup
            row
            aria-label="type of billing"
            name="type of billing"
            value={billing}
            onChange={(e) => {
              setBilling(e.target.value);
            }}
          >
            <FormControlLabel
              value="by_field"
              control={<Radio color="primary" />}
              label="by Field"
              labelPlacement="end"
            />
            <FormControlLabel
              value="by_haa"
              control={<Radio color="primary" />}
              label="by Haa"
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
        <div className={classes.radioGroupRoot}>
          <div className={classes.typeOfBiling}>Data Version :</div>
          <RadioGroup
            row
            aria-label="data version"
            name="data version"
            value={"1"}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Latest"
              labelPlacement="end"
            />
          </RadioGroup>
        </div>

        <div className={classes.textFields}>
          <InputField
            label="Location in format of minLongitude"
            defaultValue=""
            value={bBoxPoints && bBoxPoints[0]}
            className="mr-12"
            onChange={(e) => {
              setBBoxPoints([
                parseFloat(e.target.value),
                bBoxPoints[1],
                bBoxPoints[2],
                bBoxPoints[3],
              ]);
            }}
          ></InputField>
          <InputField
            label="Location in format of minLatitude"
            defaultValue=""
            value={bBoxPoints && bBoxPoints[1]}
            onChange={(e) => {
              setBBoxPoints([
                bBoxPoints[0],
                parseFloat(e.target.value),
                bBoxPoints[2],
                bBoxPoints[3],
              ]);
            }}
          ></InputField>
        </div>
        <div className={classes.textFields}>
          <InputField
            label="Location in format of maxLongitude"
            defaultValue=""
            value={bBoxPoints && bBoxPoints[2]}
            className="mr-12"
            onChange={(e) => {
              setBBoxPoints([
                bBoxPoints[0],
                bBoxPoints[1],
                parseFloat(e.target.value),
                bBoxPoints[3],
              ]);
            }}
          ></InputField>
          <InputField
            label="Location in format of maxLatitude"
            defaultValue=""
            value={bBoxPoints && bBoxPoints[3]}
            onChange={(e) => {
              setBBoxPoints([
                bBoxPoints[0],
                bBoxPoints[1],
                bBoxPoints[2],
                parseFloat(e.target.value),
              ]);
            }}
          ></InputField>
        </div>
        {api_version === "development" ? (
          <>
            <div className={classes.textFields}>
              <InputField
                label="Limit"
                defaultValue=""
                className="mr-12"
                value={limit}
                onChange={(e) => {
                  setLimit(e.target.value);
                }}
              ></InputField>
              <InputField
                label="Version"
                defaultValue=""
                value={version}
                onChange={(e) => {
                  setVersion(e.target.value);
                }}
              ></InputField>
            </div>
            <div className={classes.textFields}>
              <InputField
                label="Cursor"
                defaultValue=""
                value={nextCursor}
                onChange={(e) => {
                  setNextCursor(e.target.value);
                }}
              ></InputField>
            </div>
          </>
        ) : (
          ""
        )}
        <Button
          variant={bBoxPoints && bBoxPoints.length ? "contained" : "outlined"}
          color="primary"
          onClick={handleClick}
          fullWidth={true}
          className={classes.generateResp}
        >
          Generate Response
        </Button>
      </div>

      {responseStatus && (
        <div className={classes.info}>
          <h3>Responses</h3>
          <UrlDisplay requestUrl={requestUrl} />
          <div className={classes.responseContainer}>
            <div className={classes.responseCode}>{responseStatus}</div>
            <div className={classes.rightContainer}>
              <h6>
                {responseStatus === 200 && "Successfully fetched"}{" "}
                {responseStatus === 404 && "Field not found at the location"}
              </h6>
              <ResponseDisplay
                responseJson={responseJson}
                responseStatus={responseStatus}
              />
              {responseJson &&
                responseJson.features &&
                !responseJson.features.length && (
                  <div className={classes.responseContainer}>
                    Please check the coverage API to see if we have data for the
                    region you are querying. The test token allows you to query
                    to the DR API for only a sample area of each region. If you
                    wish to query outside the sample region shown get in touch
                    with us at hello@digifarm.io
                  </div>
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BBoxDrawer;
