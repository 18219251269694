import {
  Button,
  FilledInput,
  FormControl,
  FormControlLabel,
  Input,
  makeStyles,
  RadioGroup,
  TextField,
  Radio,
  Select,
  MenuItem,
} from "@material-ui/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { store } from "../contexts/store";
import {
  setMode,
  setDrawMode,
  setFeature,
  setPolygon,
  setFieldId,
  setAPI,
} from "../contexts/actions";

import { saveAs } from "file-saver";
import { VectorTile } from "@mapbox/vector-tile";
import Protobuf from "pbf";
import { domain, api_version } from "../config";
import Disclaimer from "./Disclaimer";
import UrlDisplay from "./UrlDisplay";
import ResponseDisplay from "./ResponseDisplay";

import InputField from "./InputField";
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import TimeSeriesChart from "./TimeSeriesChart";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  info: {
    padding: "24px 20px",
    boxSizing: "border-box",
    color: "#949EA7",
    backgroundColor: "#fff",
    marginBottom: 8,
    "& h3": {
      lineHeight: "24px",
      marginBottom: 8,
      color: "#1D2023",
    },
    "& h4": {
      color: "#1D2023",
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 600,
      margin: 0,
    },
    "& h6": {
      color: "#1D2023",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
      margin: 0,
    },
  },
  iconRoot: {
    fill: "none",
  },
  flex: {
    display: "flex",
  },
  flex1: {
    flex: 1,
    wordWrap: "anywhere",
  },
  rightContainer: {
    flex: 1,
    wordWrap: "anywhere",
    color: "#1D2023",
    "& h6": {
      marginBottom: 16,
    },
  },
  responseContainer: {
    display: "flex",
    marginBottom: 16,
    fontWeight: 700,
    maxWidth: "100%",
    marginTop: 16,
  },
  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: "20px",
  },
  responseJson: {
    backgroundColor: "#EEEFF1",
    borderRadius: 4,
    padding: "8px 16px",
    position: "relative",
    maxWidth: 420,
    minHeight: 100,
    maxHeight: 520,
    overflowY: "scroll",
  },
  inputRoot: {
    borderRadius: 4,
  },
  generateResp: {
    marginTop: 16,
    textTransform: "capitalize",
  },

  downloadButton: {
    position: "absolute",
    top: 60,
    right: 16,
    textTransform: "capitalize",
    borderRadius: 4,
    "@media (max-width: 600px)": {
      position: "static",
      marginBottom: 8,
    },
  },
  textFields: {
    display: "flex",
    marginTop: 12,
  },
  shrinkLabel: {
    fontSize: 14,
  },
  leftButton: {
    textTransform: "lowercase",
    lineHeight: "24px",
    padding: "6px",
    borderRadius: 0,
    flex: 1,
    border: "1px solid #01B075",
    color: "#01B075",
    fontWeight: 600,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  rightButton: {
    textTransform: "lowercase",
    lineHeight: "24px",
    padding: "6px",
    borderRadius: 0,
    flex: 1,
    border: "1px solid #01B075",
    color: "#01B075",
    fontWeight: 600,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  activeModeButton: {
    backgroundColor: "#01B075",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#01B075",
      color: "#fff",
    },
  },
  radioFormControl: {
    verticalAlign: "middle",
    marginLeft: 16,
  },
  parameters: {
    marginTop: "24px !important",
  },
  radioGroupRoot: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
  },
  typeOfBiling: {
    fontSize: 16,
    marginRight: 32,
    color: "#1D2023",
    width: 118,
  },
  select: {
    // paddingTop: 12,
    // paddingBottom: 12,
    backgroundColor: "#EEEFF1",
    textAlign: "left",
    // border: "1px solid #EEEFF1",
  },
  selectIcon: {
    color: theme.palette.primary.main,
  },
  fieldResponseContainer: {
    position: "absolute",
    top: 80,
    width: "100%",
    backgroundColor: "#fff",
    paddingBottom: 80,
    zIndex: 1,
    boxShadow: "0px -7px 32px 0px rgba(170,170,170,1)",
    borderRadius: 24,
  },
  borderRadius: {
    borderRadius: 24,
  },
  clearFieldButton: {
    borderRadius: 4,
    backgroundColor: "#E5F7F1",
    textTransform: "capitalize",
    fontSize: 12,
    fontWeight: 600,
    position: "absolute",
    top: 20,
    right: 24,
  },
  fieldResponseRef: {
    position: "relative",
    top: -36,
  },
}));

const baseUrl = `${domain}/${api_version}`;
// "https://2aqfsaq392.execute-api.eu-central-1.amazonaws.com/production";
// "https://api.digifarm.io/v1";

const params = new URLSearchParams(window.location.search);

const customLowRes = params.get("customLowRes");

const TimeSeriesFieldIdDrawer = (props) => {
  const classes = useStyles();

  const {
    state: { mode, polygon, tile, fieldId, dataLayer },
    dispatch,
  } = useContext(store);

  const defaultToken = "a0731a8c-5259-4c68-af3a-7ad4f6d53faa";

  const [bBoxPoints, setBBoxPoints] = useState([]);
  const [outputFormat, setOutputFormat] = useState("geojson");
  const [tileCoordinates, setTileCoordinates] = useState([]);
  const [startDate, setStartDate] = React.useState(new Date("2017-01-01"));
  const [endDate, setEndDate] = React.useState(new Date(Date.now()));

  const [token, setToken] = useState(defaultToken);
  const [responseJson, setResponseJson] = useState();
  const [responseStatus, setResponseStatus] = useState();
  const [requestUrl, setRequestUrl] = useState();
  const [fieldIdInput, setFieldIdInput] = useState();
  const [fieldResponseJson, setFieldResponseJson] = useState();
  const [fieldresponseStatus, setFieldResponseStatus] = useState();
  const [fieldRequestUrl, setFieldRequestUrl] = useState();
  const [mvtResponse, setMvtResponse] = useState();
  const step2DivRef = useRef(null);
  const step2ResponseRef = useRef(null);
  const [mapDrawMode, setMapDrawMode] = useState(dataLayer.drawMode);

  const [source, setSource] = useState("s2l2a");
  const [type, setType] = useState("normal");

  useEffect(() => {
    if (polygon && polygon[0] && polygon[0].length) {
      let maxCallback = (max, cur) =>
        Math.max(parseFloat(max), parseFloat(cur));
      let minCallback = (max, cur) =>
        Math.min(parseFloat(max), parseFloat(cur));

      const minLng = polygon[0]
        .map((point) => point[0])
        .reduce(minCallback, Infinity);
      const maxLng = polygon[0]
        .map((point) => point[0])
        .reduce(maxCallback, -Infinity);

      const minLat = polygon[0]
        .map((point) => point[1])
        .reduce(minCallback, Infinity);
      const maxLat = polygon[0]
        .map((point) => point[1])
        .reduce(maxCallback, -Infinity);

      setBBoxPoints([minLng, minLat, maxLng, maxLat]);
    }
  }, [polygon]);

  useEffect(() => {
    if (tile && tile.length) {
      setTileCoordinates(tile);
    }
  }, [tile]);

  const comparePolygonArrays = (array1, array2) => {
    return array1.every(
      (value, index) =>
        value[0] == array2[index][0] && value[1] == array2[index][1]
    );
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  useEffect(() => {
    setMapDrawMode(dataLayer.drawMode);
  }, [dataLayer.drawMode]);

  useEffect(() => {
    if (mvtResponse) {
      try {
        var tile = new VectorTile(new Protobuf(mvtResponse));

        const features = [];

        for (let i = 0; i < tile.layers["digifarm"].length; i++) {
          features.push(
            tile.layers["digifarm"]
              .feature(i)
              .toGeoJSON(
                tileCoordinates[0],
                tileCoordinates[1],
                tileCoordinates[2]
              )
          );
        }
        dispatch({
          type: setFeature,
          payload: {
            feature: {
              type: "FeatureCollection",
              features,
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [mvtResponse]);

  const handleClick = () => {
    if (mapDrawMode === "draw_rectangle") {
      const fetchUrl = !customLowRes
        ? baseUrl +
          "/delineated-fields/low-res" +
          "?token=" +
          token +
          "&bbox=" +
          bBoxPoints.join(",") +
          "&data_version=latest"
        : `https://polygons.digifarm.io/development/layers/${customLowRes}/polygons` +
          "?token=" +
          token +
          "&bbox=" +
          bBoxPoints.join(",") +
          "&data_version=latest";

      const rectangle = [
        [
          [bBoxPoints[0], bBoxPoints[3]],
          [bBoxPoints[2], bBoxPoints[3]],
          [bBoxPoints[2], bBoxPoints[1]],
          [bBoxPoints[0], bBoxPoints[1]],
          [bBoxPoints[0], bBoxPoints[3]],
        ],
      ];

      if (polygon && polygon.length) {
        if (!comparePolygonArrays(rectangle[0], polygon[0])) {
          dispatch({
            type: setPolygon,
            payload: {
              polygon: rectangle,
            },
          });
        }
      }

      setRequestUrl(fetchUrl);
      console.log(fetchUrl);
      fetch(fetchUrl)
        .then((response) => {
          setResponseStatus(response.status);
          return response.json();
        })
        .then((data) => {
          setFieldIdInput("");
          setFieldResponseStatus(null);
          setResponseJson(data);
          console.log(data, "data");
          dispatch({
            type: setFeature,
            payload: {
              feature: data,
            },
          });
          setTimeout(() => {
            step2DivRef.current &&
              step2DivRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
              });
          }, 200);
        });
    } else {
      if (tileCoordinates.length) {
        const fetchUrl =
          baseUrl +
          "/delineated-fields/low-res/merc" +
          "?token=" +
          token +
          "&x=" +
          tileCoordinates[0] +
          "&y=" +
          tileCoordinates[1] +
          "&z=" +
          tileCoordinates[2] +
          "&ext=" +
          outputFormat +
          "&data_version=latest";

        setRequestUrl(fetchUrl);
        console.log(fetchUrl);
        if (outputFormat === "mvt") {
          fetch(fetchUrl)
            .then((response) => {
              setResponseStatus(response.status);
              return response.arrayBuffer();
            })
            .then((buffer) => {
              setMvtResponse(buffer);
              setTimeout(() => {
                step2DivRef.current &&
                  step2DivRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                  });
              }, 200);
            });

          return;
        }
        fetch(fetchUrl)
          .then((response) => {
            console.log(response);
            setResponseStatus(response.status);

            return response.json();
          })
          .then((data) => {
            setFieldIdInput("");
            setFieldResponseStatus(null);
            setResponseJson(data);
            dispatch({
              type: setFeature,
              payload: {
                feature: data,
              },
            });
            setTimeout(() => {
              step2DivRef.current &&
                step2DivRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                });
            }, 200);
          });
      }
    }
  };

  const fetchByFieldId = () => {
    const fetchUrl =
      `https://6k36erufmg.execute-api.eu-central-1.amazonaws.com/frk-dev/indices-timeseries/${fieldId}` +
      "?token=" +
      token +
      "&names=" +
      "EVI,EVI2,NDVI,NDMI,MI,B01,B02,B03,B04,B05,B06,B07,B08,B08A,B09,B11,B12" +
      "&dates=" +
      startDate.getTime() +
      "," +
      endDate.getTime();
    setFieldRequestUrl(fetchUrl);
    console.log(fetchUrl);
    fetch(fetchUrl)
      .then((response) => {
        setFieldResponseStatus(response.status);
        return response.json();
      })
      .then((data) => {
        setFieldResponseJson(data);
        // dispatch({
        //   type: setFeature,
        //   payload: {
        //     feature: data,
        //   },
        // });
        setTimeout(() => {
          step2ResponseRef.current &&
            step2ResponseRef.current.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
        }, 200);
      });
  };

  const downloadPbf = () => {
    const blob = new Blob([mvtResponse]);
    saveAs(
      blob,
      `${tileCoordinates[0]}_${tileCoordinates[1]}_${tileCoordinates[2]}.pbf`
    );
  };

  useEffect(() => {
    setFieldIdInput(fieldId);
  }, [fieldId]);

  const clearFieldData = () => {
    setFieldResponseStatus(null);
    setFieldResponseJson(null);
    // setFieldIdInput("");
    // dispatch({
    //   type: setFieldId,
    //   payload: {
    //     fieldId: null,
    //   },
    // });
    setTimeout(() => {
      step2DivRef.current &&
        step2DivRef.current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
    }, 200);
  };

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h4>Step 1. Area selection</h4>
        <p>Returns low resolution fields for the given bbox/tile coordinates</p>
        <div className="flex">
          <Button
            className={[
              classes.leftButton,
              mapDrawMode === "draw_rectangle" && classes.activeModeButton,
            ].join(" ")}
            onClick={() => {
              setFieldResponseJson(null);
              setFieldRequestUrl("");
              setFieldResponseStatus(null);
              setOutputFormat("geojson");
              dispatch({
                type: setAPI,
                payload: {
                  api: "lowResBBOX",
                  drawMode: "draw_rectangle",
                },
              });
            }}
          >
            via bounding box
          </Button>

          {!customLowRes && (
            <Button
              className={[
                classes.rightButton,
                mapDrawMode !== "draw_rectangle" && classes.activeModeButton,
              ].join(" ")}
              onClick={() => {
                setResponseJson(null);
                setRequestUrl("");
                setResponseStatus(null);
                dispatch({
                  type: setAPI,
                  payload: {
                    api: "lowResTile",
                    drawMode: "",
                  },
                });
              }}
            >
              via vector tile
            </Button>
          )}
        </div>
        <h3 className={classes.parameters}>Parameters</h3>

        <InputField
          label="Client Token provided by Digifarm and EarthDaily Analytics"
          variant="filled"
          value={token}
          onChange={(e) => {
            setToken(e.target.value);
          }}
        ></InputField>
        <div className={classes.radioGroupRoot}>
          <div className={classes.typeOfBiling}>Data Version :</div>
          <RadioGroup
            row
            aria-label="data version"
            name="data version"
            value={"1"}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Latest"
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
      </div>

      {mapDrawMode === "draw_rectangle" ? (
        <>
          <div className={classes.info}>
            <div className={classes.textFields}>
              <InputField
                label="Location in format of minLongitude"
                value={bBoxPoints && bBoxPoints[0]}
                className="mr-12"
                onChange={(e) => {
                  setBBoxPoints([
                    parseFloat(e.target.value),
                    bBoxPoints[1],
                    bBoxPoints[2],
                    bBoxPoints[3],
                  ]);
                }}
              />

              <InputField
                label="Location in format of minLatitude"
                value={bBoxPoints && bBoxPoints[1]}
                onChange={(e) => {
                  setBBoxPoints([
                    bBoxPoints[0],
                    parseFloat(e.target.value),
                    bBoxPoints[2],
                    bBoxPoints[3],
                  ]);
                }}
              ></InputField>
            </div>
            <div className={classes.textFields}>
              <InputField
                label="Location in format of maxLongitude"
                value={bBoxPoints && bBoxPoints[2]}
                className="mr-12"
                onChange={(e) => {
                  setBBoxPoints([
                    bBoxPoints[0],
                    bBoxPoints[1],
                    parseFloat(e.target.value),
                    bBoxPoints[3],
                  ]);
                }}
              ></InputField>
              <InputField
                label="Location in format of maxLatitude"
                value={bBoxPoints && bBoxPoints[3]}
                onChange={(e) => {
                  setBBoxPoints([
                    bBoxPoints[0],
                    bBoxPoints[1],
                    bBoxPoints[2],
                    parseFloat(e.target.value),
                  ]);
                }}
              ></InputField>
            </div>
            <Button
              variant={
                bBoxPoints && bBoxPoints.length ? "contained" : "outlined"
              }
              color="primary"
              onClick={handleClick}
              fullWidth={true}
              className={classes.generateResp}
            >
              Show field boundaries
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={classes.info}>
            <div className={classes.textFields}>
              <InputField
                label="Coordinate X"
                value={tileCoordinates.length && tileCoordinates[0]}
                className="mr-12"
                onChange={(e) => {
                  setTileCoordinates([
                    parseFloat(e.target.value),
                    tileCoordinates[1],
                    tileCoordinates[2],
                  ]);
                }}
              ></InputField>
              <InputField
                label="Coordinate Y"
                value={tileCoordinates.length && tileCoordinates[1]}
                className="mr-12"
                onChange={(e) => {
                  setTileCoordinates([
                    tileCoordinates[0],
                    parseFloat(e.target.value),
                    tileCoordinates[2],
                  ]);
                }}
              ></InputField>
              <InputField
                label="Coordinate Z"
                variant="filled"
                value={tileCoordinates.length && tileCoordinates[2]}
                onChange={(e) => {
                  setTileCoordinates([
                    tileCoordinates[0],
                    tileCoordinates[1],
                    parseFloat(e.target.value),
                  ]);
                }}
              ></InputField>
            </div>
            <div>
              Type of Output:
              <FormControl
                component="fieldset"
                className={classes.radioFormControl}
              >
                <RadioGroup
                  row
                  aria-label="output format"
                  name="format"
                  value={outputFormat}
                  onChange={(e) => {
                    setOutputFormat(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="geojson"
                    control={<Radio color="primary" />}
                    label="GeoJson"
                  />
                  <FormControlLabel
                    value="mvt"
                    control={<Radio color="primary" />}
                    label="MVT"
                  />
                </RadioGroup>
              </FormControl>
              <div>
                Use "digifarm" as the source layer when using as a vector layer
                in mapbox
              </div>
            </div>
            <Button
              variant={tile && tile.length ? "contained" : "outlined"}
              color="primary"
              onClick={handleClick}
              fullWidth={true}
              className={classes.generateResp}
            >
              Show field boundaries
            </Button>
          </div>
        </>
      )}
      {responseStatus && (
        <>
          <div className={[classes.info, classes.borderRadius].join(" ")}>
            <h3>Responses</h3>
            <UrlDisplay requestUrl={requestUrl} />
            <div className={classes.responseContainer}>
              <div className={classes.responseCode}>{responseStatus}</div>
              <div className={classes.rightContainer}>
                {outputFormat === "mvt" && responseStatus !== 204 ? (
                  <div className={classes.responseJson}>
                    <>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          downloadPbf(mvtResponse);
                        }}
                        className={classes.downloadButton}
                      >
                        Save as file
                      </Button>
                    </>
                  </div>
                ) : (
                  <ResponseDisplay
                    responseJson={responseJson}
                    responseStatus={responseStatus}
                  />
                )}
                {((responseJson &&
                  responseJson.features &&
                  !responseJson.features.length &&
                  outputFormat !== "mvt") ||
                  (outputFormat === "mvt" && responseStatus === 204)) && (
                  <div className={classes.responseContainer}>
                    Please check the coverage API to see if we have data for the
                    region you are querying. The test token allows you to query
                    to the DR API for only a sample area of each region. If you
                    wish to query outside the sample region shown get in touch
                    with us at hello@digifarm.io
                  </div>
                )}
              </div>
            </div>
          </div>
          {!fieldresponseStatus && (
            <div className={classes.info} ref={step2DivRef}>
              <h4>Step 2. Field boundary selection</h4>
              <p>Click on the field to select the field id</p>
              <InputField
                label="Field Id"
                value={fieldIdInput}
                onChange={(e) => {
                  dispatch({
                    type: setFieldId,
                    payload: {
                      fieldId: e.target.value,
                    },
                  });
                }}
              ></InputField>

              {/* <div className={classes.textFields}>
              <Select
                value={source}
                onChange={(e) => {
                  setSource(e.target.value);
                }}
                classes={{
                  root: classes.select,
                  icon: classes.selectIcon,
                  outlined: classes.outlined,
                }}
                fullWidth
                variant="outlined"
                className="mr-12"
              >
                <MenuItem value={"s2l2a"}>S2L2A</MenuItem>
                <MenuItem value={"landsat"}>Landsat</MenuItem>
              </Select>
              <Select
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                classes={{
                  root: classes.select,
                  icon: classes.selectIcon,
                  outlined: classes.outlined,
                }}
                fullWidth
                variant="outlined"
              >
                <MenuItem value={"normal"}>Normal</MenuItem>
                <MenuItem value={"median_10d"}>Median 10d</MenuItem>
                <MenuItem value={"interpolated_10d"}>Interpolated 10d</MenuItem>
              </Select>
            </div> */}
              <div className={classes.textFields}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    // disableToolbar
                    inputVariant="filled"
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.inputRoot,
                      },
                    }}
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="Start Date"
                    value={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    // views={["day", "month", "year"]}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className="mr-12"
                    fullWidth
                  />
                  {/* <DesktopDatePicker
                    value={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  /> */}

                  <KeyboardDatePicker
                    // disableToolbar
                    inputVariant="filled"
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.inputRoot,
                      },
                    }}
                    // views={["day", "month", "year"]}
                    format="dd/MM/yyyy"
                    margin="normal"
                    label="End Date"
                    value={endDate}
                    onChange={(date) => {
                      setEndDate(date);
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className="mr-12"
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </div>

              <Button
                variant={fieldId ? "contained" : "outlined"}
                color="primary"
                onClick={fetchByFieldId}
                fullWidth={true}
                className={classes.generateResp}
              >
                Fetch Time series data
              </Button>
            </div>
          )}

          {fieldresponseStatus && (
            <div className={classes.fieldResponseContainer}>
              <div className={[classes.info, classes.borderRadius].join(" ")}>
                <div
                  ref={step2ResponseRef}
                  className={classes.fieldResponseRef}
                ></div>
                <h3>Time Series Data</h3>
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.clearFieldButton}
                    onClick={clearFieldData}
                  >
                    Close Response
                  </Button>
                </div>

                <UrlDisplay requestUrl={fieldRequestUrl} />

                <TimeSeriesChart data={fieldResponseJson} />

                <div className={classes.responseContainer}>
                  <div className={classes.responseCode}>{responseStatus}</div>
                  <div className={classes.rightContainer}>
                    <ResponseDisplay
                      responseJson={fieldResponseJson}
                      responseStatus={fieldresponseStatus}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TimeSeriesFieldIdDrawer;
