import React, { useState, useEffect, useContext } from "react";
import Map from "../components/Map";
import {
  Select,
  MenuItem,
  makeStyles,
  InputLabel,
  FormControl,
  Button,
  Link,
} from "@material-ui/core";
import {
  BBoxIcon,
  GlobeIcon,
  InfoIcon,
  PointerIcon,
  DocumentationIcon,
  CoverageIcon,
} from "../icons";
import LeftDrawer from "../components/LeftDrawer";
import RightDrawer from "../components/RightDrawer";
import { setLocation, setDataLayer, setAPI } from "../contexts/actions";
import { store } from "../contexts/store";
import { getDataLayerConfig } from "../api";

const useStyles = makeStyles((theme) => ({
  dropdownContainer: {
    position: "absolute",
    top: 16,
    left: 16,
    zIndex: 1,
    display: "flex",
    "& >div": {
      marginRight: 8,
      marginBottom: 4,
    },
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  select: {
    paddingTop: 12,
    paddingBottom: 12,
    backgroundColor: "#fff",
    minWidth: 120,
    textAlign: "left",
    //border: "1px solid #EEEFF1",
    // "&:hover": {
    //   border: "1px solid #949EA7",
    // },
    fontWeight: 600,
    "&:focus": {
      backgroundColor: "#fff",
      borderRadius: 8,
      border: "none",
    },
  },
  formControl: {
    "&:hover": {
      border: "1px solid #949EA7",
    },
    // Mui focused seems to be creating a green border.  fix that
  },
  outlined: {},
  selectIcon: {
    color: theme.palette.primary.main,
  },
  toggleButtonGroup: {
    backgroundColor: "#fff",
    border: "none",
    display: "inline-block",
    borderRadius: 8,
    padding: 4,
    marginLeft: 16,
  },
  toggleButton: {
    fontWeight: 600,
    textTransform: "capitalize",
    backgroundColor: "#fff",
    boxShadow: "none",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      boxShadow: "none",
    },
  },
  toggleButtonActive: {
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  aboutApiQueries: {
    position: "absolute",
    bottom: 16,
    left: 16,
    zIndex: 4,
    lineHeight: "16px",
  },
  aboutApiQueriesButton: {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
    textTransform: "capitalize",
    color: "#01B075",
    fontWeight: 600,
  },
  infoIcon: {
    fill: "none",
    stroke: "#01B075",
    marginRight: 4,
  },
  iconRoot: {
    fill: "none",
  },
  apiSelection: {
    position: "absolute",
    left: 16,
    top: "40vh",
    backgroundColor: "#fff",
    borderRadius: 8,
    padding: "0 6px",
    textAlign: "center",
    "@media (max-width: 600px)": {
      top: "120px",
    },
  },
  apiButton: {
    fontSize: 10,
    lineHeight: 1.2,
    fontWeight: 500,
    padding: "9px 0",
    "& svg path": {
      stroke: "#1D2023",
    },
    cursor: "pointer",
  },
  apiButtonActive: {
    color: "#01B075",
    "& svg path": {
      stroke: "#01B075",
    },
    fontSize: 10,
  },
}));

const MapPage = () => {
  const classes = useStyles();
  const [region, setRegion] = useState("norway");

  const {
    state: { dataLayer },
    dispatch,
  } = useContext(store);

  //Extract zoom from url parameters when the window loads
  const initialZoom = window.location.hash
    ? window.location.hash.substr(1).split("/")[0]
    : 13;
  const [zoom, setZoom] = useState(initialZoom);
  const [toggleLeftDrawer, setToggleLeftDrawer] = useState(false);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState(true);
  const [dataLayerConfig, setDataLayerConfig] = useState({});

  const toggleRightDrawer = () => {
    setIsRightDrawerOpen(!isRightDrawerOpen);
  };

  useEffect(() => {
    getDataLayerConfig().then((config) => {
      setDataLayerConfig(config);
      setRegion(Object.keys(config[dataLayer.type].locations)[0]);
    });
  }, []);

  return (
    <div className="map-and-drawer">
      <div className={classes.dropdownContainer}>
        <FormControl>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Location
          </InputLabel>
          <Select
            variant="outlined"
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            classes={{
              root: classes.select,
              icon: classes.selectIcon,
              outlined: classes.outlined,
            }}
            value={region}
            onChange={(event) => {
              setRegion(event.target.value);
              dispatch({
                type: setLocation,
                payload: {
                  location:
                    dataLayerConfig[dataLayer.type].locations[
                      event.target.value
                    ],
                },
              });
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {dataLayer &&
              Object.keys(
                dataLayerConfig?.[dataLayer.type]?.locations ?? {}
              ).map((key) => {
                return (
                  <MenuItem key={key} value={key}>
                    {dataLayerConfig[dataLayer.type].locations[key].name}
                    <img
                      src={`https://flagcdn.com/20x15/${
                        dataLayerConfig[dataLayer.type].locations[key]
                          .country_code
                      }.png`}
                      width="18"
                      height="auto"
                      className="ml-2"
                      alt={
                        dataLayerConfig[dataLayer.type].locations[key]
                          .country_code
                      }
                    ></img>
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            Data Layer
          </InputLabel>
          <Select
            variant="outlined"
            labelId="demo-simple-select-placeholder-label-label"
            id="demo-simple-select-placeholder-label"
            classes={{
              root: classes.select,
              icon: classes.selectIcon,
              outlined: classes.outlined,
            }}
            value={dataLayer.type}
            onChange={(event) => {
              const listOfLocationsForTheDatalayer = Object.keys(
                dataLayerConfig[event.target.value].locations
              );
              const newLocation = listOfLocationsForTheDatalayer[0];

              setRegion(newLocation);

              const newLocationObj = {
                location:
                  dataLayerConfig[event.target.value].locations[newLocation],
              };

              // * Simplify the conditional code and make it more readable.
              //
              // const datalayerPayload = {
              //   type: event.target.value,
              //   api:
              //     event.target.value === "delineatedFields"
              //       ? "point"
              //       : event.target.value === "deepResImages"
              //       ? showDRAPI
              //         ? "xyz"
              //         : "coverage"
              //       : "lowResBBOX",
              //   drawMode:
              //     event.target.value === "delineatedFields"
              //       ? "draw_single_point"
              //       : event.target.value === "deepResImages"
              //       ? undefined
              //       : "draw_rectangle",
              // };
              //

              // * Simplify code
              const dataLayerPayload = {
                type: event.target.value,
              };

              switch (event.target.value) {
                case "delineatedFields":
                  dataLayerPayload.api = "point";
                  dataLayerPayload.drawMode = "draw_single_point";
                  break;

                case "deepResImages":
                  dataLayerPayload.api = dataLayerConfig[event.target.value]
                    .tileXYZ
                    ? "xyz"
                    : "coverage";
                  dataLayerPayload.drawMode = undefined;
                  break;

                default:
                  dataLayerPayload.api = "lowResBBOX";
                  dataLayerPayload.drawMode = "draw_rectangle";
                  break;
              }

              const payload =
                event.target.value !== "timeSeries"
                  ? {
                      dataLayer: dataLayerPayload,
                      ...newLocationObj,
                    }
                  : { dataLayer: dataLayerPayload };
              dispatch({
                type: setDataLayer,
                payload: payload,
              });
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {Object.keys(dataLayerConfig).map((api) => (
              <MenuItem key={api} value={api}>
                {dataLayerConfig[api].name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <LeftDrawer
        open={toggleLeftDrawer}
        onClose={() => setToggleLeftDrawer(false)}
      />

      <Map
        layer={dataLayer.type}
        zoom={zoom}
        setZoom={setZoom}
        toggleRightDrawer={toggleRightDrawer}
        isRightDrawerOpen={isRightDrawerOpen}
        dataLayerConfig={dataLayerConfig}
      ></Map>

      {/* Based on the dataLayer value, can display appropriate API selection button set here. And also appropriate mode set, right drawer should be opened
       */}
      {dataLayer.type === "delineatedFields" ? (
        <div className={classes.apiSelection}>
          <div
            className={[
              classes.apiButton,
              dataLayer.api === "lowResBBOX" || dataLayer.api === "lowResTile"
                ? classes.apiButtonActive
                : "",
            ].join(" ")}
            role="button"
            onClick={() =>
              dispatch({
                type: setAPI,
                payload: {
                  api: "lowResBBOX",
                  drawMode: "draw_rectangle",
                },
              })
            }
          >
            <GlobeIcon />
            <div>Field ID</div>
          </div>
          <div
            className={
              dataLayer.api === "point"
                ? [classes.apiButtonActive, classes.apiButton].join(" ")
                : [classes.apiButton]
            }
            role="button"
            onClick={() =>
              dispatch({
                type: setAPI,
                payload: {
                  api: "point",
                  drawMode: "draw_single_point",
                },
              })
            }
          >
            <PointerIcon classes={{ root: classes.iconRoot }} />
            <div>Point</div>
          </div>
          <div
            className={
              dataLayer.api === "bbox"
                ? [classes.apiButtonActive, classes.apiButton].join(" ")
                : [classes.apiButton]
            }
            role="button"
            onClick={() =>
              dispatch({
                type: setAPI,
                payload: {
                  api: "bbox",
                  drawMode: "draw_rectangle",
                },
              })
            }
          >
            <BBoxIcon classes={{ root: classes.iconRoot }} />
            <div>Bbox</div>
          </div>
          <div
            className={
              dataLayer.api === "coverage"
                ? [classes.apiButtonActive, classes.apiButton].join(" ")
                : [classes.apiButton]
            }
            role="button"
            onClick={() =>
              dispatch({
                type: setAPI,
                payload: {
                  api: "coverage",
                  drawMode: "",
                },
              })
            }
          >
            <CoverageIcon classes={{ root: classes.iconRoot }} />
            <div>Coverage</div>
          </div>
        </div>
      ) : dataLayer.type === "deepResImages" ? (
        <div className={classes.apiSelection}>
          {dataLayerConfig[dataLayer.type].tileXYZ && (
            <>
              <div
                className={[
                  classes.apiButton,
                  dataLayer.api === "xyz" ? classes.apiButtonActive : "",
                ].join(" ")}
                role="button"
                onClick={() =>
                  dispatch({
                    type: setAPI,
                    payload: {
                      api: "xyz",
                      // drawMode: "draw_rectangle",
                    },
                  })
                }
              >
                <GlobeIcon />
                <div>Tile URL</div>
              </div>
              <div
                className={[
                  classes.apiButton,
                  dataLayer.api === "bbox" ? classes.apiButtonActive : "",
                ].join(" ")}
                role="button"
                onClick={() =>
                  dispatch({
                    type: setAPI,
                    payload: {
                      api: "bbox",
                      drawMode: "draw_rectangle",
                    },
                  })
                }
              >
                <BBoxIcon classes={{ root: classes.iconRoot }} />
                <div>BBOX</div>
              </div>
            </>
          )}

          <div
            className={
              dataLayer.api === "coverage"
                ? [classes.apiButtonActive, classes.apiButton].join(" ")
                : [classes.apiButton]
            }
            role="button"
            onClick={() =>
              dispatch({
                type: setAPI,
                payload: {
                  api: "coverage",
                  drawMode: "",
                },
              })
            }
          >
            <CoverageIcon classes={{ root: classes.iconRoot }} />
            <div>Coverage</div>
          </div>
        </div>
      ) : (
        <div className={classes.apiSelection}>
          <div
            className={[
              classes.apiButton,
              dataLayer.api === "lowResBBOX" || dataLayer.api === "lowResTile"
                ? classes.apiButtonActive
                : "",
            ].join(" ")}
            role="button"
            onClick={() =>
              dispatch({
                type: setAPI,
                payload: {
                  api: "lowResBBOX",
                  drawMode: "draw_rectangle",
                },
              })
            }
          >
            <GlobeIcon />
            <div>Field ID</div>
          </div>
          <div
            className={
              dataLayer.api === "coverage"
                ? [classes.apiButtonActive, classes.apiButton].join(" ")
                : [classes.apiButton]
            }
            role="button"
            onClick={() =>
              dispatch({
                type: setAPI,
                payload: {
                  api: "coverage",
                  drawMode: "",
                },
              })
            }
          >
            <CoverageIcon classes={{ root: classes.iconRoot }} />
            <div>Coverage</div>
          </div>
        </div>
      )}

      <div className={classes.aboutApiQueries}>
        <Button
          className={classes.aboutApiQueriesButton}
          onClick={() => setToggleLeftDrawer(true)}
          variant="contained"
        >
          <InfoIcon classes={{ root: classes.infoIcon }} /> about API queries
        </Button>
        <br />
        <br />
        <Button
          className={classes.aboutApiQueriesButton}
          variant="contained"
          component={Link}
          target="_blank"
          href="https://digifarming.readme.io/reference"
          underline="none"
        >
          <DocumentationIcon classes={{ root: classes.infoIcon }} /> Full
          Documentation
        </Button>
      </div>

      <RightDrawer
        open={isRightDrawerOpen}
        onClose={() => {
          setIsRightDrawerOpen(false);
        }}
      />
    </div>
  );
};

export default MapPage;
