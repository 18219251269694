const BASE_URL = "https://api-docs-data.s3.eu-central-1.amazonaws.com";
const env = process.env.REACT_APP_API_CONFIG_ENV;

export async function getDataLayerConfig() {
  try {
    const response = await fetch(`${BASE_URL}/${env}/config.json`);
    const result = response.json();

    return result;
  } catch (error) {
    return {};
  }
}

export async function getMapBaseLayerList() {
  try {
    const response = await fetch(`${BASE_URL}/${env}/map-base-layers.json`);
    const result = response.json();

    return result;
  } catch (error) {
    return [];
  }
}

export async function getMapPlaceLabelLayerList() {
  try {
    const response = await fetch(
      `${BASE_URL}/${env}/map-place-label-layers.json`
    );
    const result = response.json();

    return result;
  } catch (error) {
    return [];
  }
}

export async function getMapStyle() {
  try {
    const response = await fetch(`${BASE_URL}/${env}/map-style.json`);
    const result = response.json();

    return result;
  } catch (error) {
    return {};
  }
}
