import React, { useState, useEffect, useRef, useContext } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import {
  Button,
  Drawer,
  Icon,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";
import { GlobeIcon, LineGlobeIcon } from "../icons";
import SinglePointMode from "./modes/draw_single_point";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import { store } from "../contexts/store";
import {
  setMode,
  setPoint,
  setPolygon,
  setTile,
  setFieldId,
  setTiles,
  setAPI,
  setDrawMode,
} from "../contexts/actions";

import DrawRectangle from "mapbox-gl-draw-rectangle-mode";
import DrawAssistedRectangle from "@geostarters/mapbox-gl-draw-rectangle-assisted-mode";
import tilebelt from "@mapbox/tilebelt";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import turf from "turf";
import {
  addBaseMapLayers,
  addPlaceLabelLayers,
  turnOffBaseMapLayers,
  turnOnBaseMapLayers,
  getTilesForCurrentBounds,
} from "../util/mapUtils";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import {
  getMapBaseLayerList,
  getMapPlaceLabelLayerList,
  getMapStyle,
} from "../api";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZ2lyaXNocGFsbGFnYXR0aSIsImEiOiJja2h6NGVuc20wYndjMnlxaDExbzlsa3B2In0.e94ZfcoPleAA2nqjeX10ag";
// "pk.eyJ1Ijoicm9oaXRzaGV0dHkwMCIsImEiOiJjanpmOXVoOXowOXF6M2lsdG95NmF5dWEzIn0.yuI5HCaw8Ka2-hMQM5RG4A";

let heightOfTheWindow = window.innerHeight;

window.addEventListener("resize", () => {
  heightOfTheWindow = window.innerHeight;
});

const useStyles = makeStyles({
  hoverDisplayContainer: {
    position: "absolute",
    left: 60,
    top: 0,
    zIndex: 1,
    minHeight: 220,
    paddingLeft: 8,
    minWidth: 160,
    textAlign: "left",
    color: "#fff",
  },
  shadowGenerator: {
    padding: 30,
    zIndex: 1,
    position: "relative",
    top: -60,
    boxShadow: "-54px 72px 138px 62px rgba(0,0,0,0.85)",
  },
  hoverContent: {
    position: "relative",
    zIndex: 2,
  },
  globeIconContainer: {
    position: "absolute",
    right: 8,
    bottom: 30,
    zIndex: 1,
    height: 48,
    width: 48,
    "@media (max-width: 600px)": {
      bottom: 56,
    },
  },
  iconRoot: {
    fill: "none",
  },
  clearButtonContainer: {
    position: "absolute",
    right: 60,
    bottom: 12,
    // display: "flex",
    // width: "100%",
    // justifyContent: "center",
  },
  clearButton: {
    backgroundColor: "#1D2023",
    fontSize: 14,
  },
  drawerToggleContainer: {
    position: "absolute",
    right: 8,
    top: "40vh",
    backgroundColor: "#fff",
    borderRadius: 8,
    "@media (max-width: 600px)": {
      display: "none",
    },
  },
  collapseDrawerButton: {
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 8,
    padding: 8,
    "&:hover": {
      color: "#999",
    },
  },
  collapseDrawerButtonMobile: {
    backgroundColor: "#000",
    color: "#fff",
    borderRadius: 8,
    padding: 8,
  },
  drawerToggleMobileOpen: {
    position: "absolute",
    right: 68,
    top: heightOfTheWindow - 340,
    backgroundColor: "#fff",
    borderRadius: 8,
    "@media (min-width: 600px)": {
      display: "none",
    },
  },
  drawerToggleMobileClosed: {
    position: "absolute",
    right: 68,
    bottom: 64,
    backgroundColor: "#fff",
    borderRadius: 8,
    "@media (min-width: 600px)": {
      display: "none",
    },
  },
});

const Map = (props) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const {
    state: {
      mode: mode,
      // drawMode: mapDrawMode,
      feature,
      point,
      polygon,
      location,
      dataLayer,
      tileURL,
      tiles,
      tileUrlCounter,
      fieldId,
    },
    dispatch,
  } = useContext(store);

  const classes = useStyles();

  const {
    zoom,
    setZoom,
    toggleRightDrawer,
    isRightDrawerOpen,
    dataLayerConfig,
  } = props;

  const [lng, setLng] = useState(11.1804734);
  const [lat, setLat] = useState(60.746268);

  const [map, setMap] = useState(null);
  const [draw, setDraw] = useState(null);

  const modeRef = useRef(null);
  const drawModeRef = useRef(null);
  const [mapDrawMode, setMapDrawMode] = useState(dataLayer.drawMode);
  const [showClearButton, setShowClearButton] = useState(false);

  const [fieldIdLocal, setFieldIdLocal] = useState("");
  const [localLocation, setLocalLocation] = useState(location);
  const [mapBaseLayer, setMapBaseLayer] = useState([]);
  const [mapPlaceLabelLayer, setMapPlaceLabelLayer] = useState([]);

  let onCreateListener;

  useEffect(() => {
    const mapDrawMode = dataLayer.drawMode;

    // if (map && mapDrawMode && drawModeRef.current !== mapDrawMode) {
    if (map && mapDrawMode) {
      console.log("adding draw");
      console.log("ee", mapDrawMode);
      const Draw = new MapboxDraw({
        displayControlsDefault: false,
        modes: Object.assign(
          {
            draw_single_point: SinglePointMode,
            draw_rectangle: DrawRectangle,
          },
          MapboxDraw.modes
        ),
      });

      map.addControl(Draw, "top-left");

      Draw.changeMode(mapDrawMode);
      setDraw(Draw);
      drawModeRef.current = mapDrawMode;
      return () => {
        console.log("Removing");
        map.removeControl(Draw);
      };
    }
    if (mapDrawMode != "draw_rectangle") {
      setShowClearButton(false);
    }
  }, [dataLayer.drawMode, map, drawModeRef]);

  useEffect(() => {
    //Can try using modeRef as well - if that makes this better

    if (dataLayer.api === "bbox" || dataLayer.api === "lowResBBOX") {
      try {
        draw && draw.deleteAll();
        draw && draw.changeMode(dataLayer.drawMode);
      } catch (e) {
        console.log(e);
      }
    }
    if (dataLayer.api === "lowResTile" && map) {
      map.on("click", function (e) {
        console.log(e);
        var coord = mapboxgl.MercatorCoordinate.fromLngLat(e.lngLat, 0);
        console.log(coord);
        dispatch({
          type: setTile,
          payload: {
            tile: tilebelt.pointToTile(
              e.lngLat.lng,
              e.lngLat.lat,
              Math.round(map.getZoom()) + 1
            ),
          },
        });
      });
    }

    // This doesn't work. The else if condition should be executed only when the previous api was coverage
    if (dataLayer.api === "coverage" && map) {
      console.log("fly to 2");
      // addPlaceLabelLayers(map);
      map.flyTo({ center: [20.6, 34.1], zoom: 1.4 });
    } else if (map) {
      console.log("fly to 1", location, localLocation);
      // removePlaceLabelLayers(map);

      // map.flyTo({
      //   center: Object.values(locationObj[dataLayer.type])[0].center,
      //   zoom: Object.values(locationObj[dataLayer.type])[0].zoom,
      // }))
      const defaultLocation = Object.values(
        dataLayerConfig?.[dataLayer.type]?.locations ?? {}
      )[0];
      console.log("default location of the dataLayer", defaultLocation.center);
      map.getZoom() < 2 &&
        (localLocation
          ? map.flyTo({
              center: localLocation.center,
              zoom: localLocation.zoom
                ? localLocation.zoom
                : mode === "draw_coverage"
                ? 7
                : 14,
            })
          : map.flyTo({
              center: defaultLocation.center,
              zoom: defaultLocation.zoom ? defaultLocation.zoom : 14,
            }));
    }

    if (map) {
      if (map && map.getSource("tileURL")) {
        console.log("removing tileURL");
        map.removeLayer("tileURL");
        map.removeSource("tileURL");
      }
    }
  }, [dataLayer.api]);

  useEffect(() => {
    console.log("tileURL", tileURL, tileUrlCounter);
    if (map && map.getSource("tileURL")) {
      console.log("removing tileURL");
      map.removeLayer("tileURL");
      map.removeSource("tileURL");
    }

    if (tileURL && map && dataLayer.api === "xyz") {
      console.log("adding tileURL");
      map.addSource("tileURL", {
        type: "raster",
        tiles: [tileURL],
        tileSize: 256,
        bounds: [
          map.getBounds()._sw.lng,
          map.getBounds()._sw.lat,
          map.getBounds()._ne.lng,
          map.getBounds()._ne.lat,
        ],
      });

      map.addLayer({
        id: "tileURL",
        source: "tileURL",
        type: "raster",
        minzoom: 0,
        maxzoom: tiles[2],
        layout: { visibility: "visible" },
      });
    }

    if (map && map.getSource("bbox-image")) {
      map.removeLayer("bbox-image");
      map.removeSource("bbox-image");
    }

    if (tileURL && map && dataLayer.api === "bbox") {
      map.addSource("bbox-image", {
        type: "image",
        url: tileURL,
        coordinates: [
          polygon[0][0],
          polygon[0][1],
          polygon[0][2],
          polygon[0][3],
        ],
      });

      map.addLayer({
        id: "bbox-image",
        type: "raster",
        source: "bbox-image",
        paint: {
          "raster-opacity": 1,
        },
        layout: { visibility: "visible" },
      });
    }
    // this doesn't work since the tile url is mostly same. the bounds are going to be the same.
    // Either I need to set the bounds from the Drawer, or use some variable that changes everytime generate response is called.
  }, [tileURL, tileUrlCounter]);

  useEffect(() => {
    let map = null;

    getMapStyle().then((mapStyle) => {
      map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: mapStyle,
        center: [lng, lat],
        zoom: zoom,
        hash: true,
        maxZoom: 17.2,
      });

      map.on("load", () => {
        map.on("zoomend", (e) => {
          setZoom(map.getZoom());
        });

        getMapBaseLayerList().then((mapBaseLayerList) => {
          setMapBaseLayer(mapBaseLayerList);
          addBaseMapLayers(map, mapBaseLayerList);
        });

        getMapPlaceLabelLayerList().then((mapPlaceLabelLayerList) => {
          setMapPlaceLabelLayer(mapPlaceLabelLayerList);
          addPlaceLabelLayers(map, mapPlaceLabelLayerList);
        });

        // map.showTileBoundaries = true;
        // map.showCollisionBoxes = true;

        onCreateListener = (e) => {
          console.log("create", e.features[0]);

          mapDrawMode === "draw_single_point" &&
            dispatch({
              type: setPoint,
              payload: {
                point: e.features[0].geometry.coordinates,
              },
            });

          mapDrawMode === "draw_rectangle" &&
            dispatch({
              type: setPolygon,
              payload: {
                polygon: e.features[0].geometry.coordinates,
              },
            });
          if (mapDrawMode === "draw_rectangle") {
            setShowClearButton(true);
          }
        };

        map.on("draw.create", onCreateListener);

        dispatch({
          type: setAPI,
          payload: {
            api: "point",
            drawMode: "draw_single_point",
          },
        });
        map.loadImage("./Group.png", function (error, image) {
          console.log("XXXXXXX");
          if (error) {
            console.log(error);
          } else {
            map.addImage("coverageMarker", image, { sdf: true });
          }
        });
      });

      map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

      map.addControl(
        new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          types: "country,region,district,place,locality,postcode",
          collapsed: true,
          marker: {
            color: "#01B075",
          },
        })
      );

      setMap(map);
      mapRef.current = map;
    });

    // Clean up on unmount
    return () => map.remove();
  }, []);

  useEffect(() => {
    const mapDrawMode = dataLayer.drawMode;
    console.log("mapdrawmode: ", mapDrawMode);
    if (map) {
      console.log("mapdrawMode: map");
      if (onCreateListener) {
        map.off("draw.create", onCreateListener);
      }
      onCreateListener = (e) => {
        console.log("create", e.features[0]);
        console.log(mapDrawMode);

        mapDrawMode === "draw_single_point" &&
          dispatch({
            type: setPoint,
            payload: {
              point: e.features[0].geometry.coordinates,
            },
          });

        mapDrawMode === "draw_rectangle" &&
          dispatch({
            type: setPolygon,
            payload: {
              polygon: e.features[0].geometry.coordinates,
            },
          });
        if (mapDrawMode === "draw_rectangle") {
          setShowClearButton(true);
        }
      };

      map.on("draw.create", onCreateListener);

      console.log("mapdrawMode: attaching Listener", onCreateListener);

      map.on("draw.delete", (e) => {
        console.log("del", e.features[0]);
      });
      map.on("draw.update", (e) => {
        console.log("update", e);
      });
    }
  }, [dataLayer.drawMode]);

  useEffect(() => {
    console.log("feature");
    let hoveredField = null;
    if (map) {
      //Remove previously added feature if it is present
      if (map.getSource("feature")) {
        console.log("removing");
        map.removeLayer("feature");
        if (map.getLayer("markers")) {
          map.removeLayer("markers");
        }
        map.removeLayer("feature-line");
        if (map.getLayer("feature-text")) {
          //The layers added to show 'View DR Image' and 'The Area is in development' in DR coverage
          map.removeLayer("feature-text");
          map.removeLayer("feature-link");
          map.removeLayer("feature-text2");
          if (map.getLayer("feature-text3")) map.removeLayer("feature-text3");
        }
        map.removeSource("feature");

        if (map.getSource("selected-feature-timeseries")) {
          map.removeLayer("selected-feature-timeseries");
          map.removeSource("selected-feature-timeseries");
        }
      }

      if (feature && feature.type) {
        console.log("adding feature", dataLayer.api);
        map.addSource("feature", {
          type: "geojson",
          data: feature,
          generateId: dataLayer.api === "coverage" ? true : false,
        });

        // if (dataLayer.api === "coverage") {
        //   map.addLayer({
        //     id: "feature",
        //     type: "fill",
        //     source: "feature",
        //     paint: {
        //       "fill-color": [
        //         "match",
        //         ["get", "status"],
        //         "archived",
        //         [
        //           ["boolean", ["feature-state", "hover"], false],
        //           "rgba(234, 137, 29,0.8)",
        //           "rgba(234, 137, 29,0.3)",
        //         ],
        //         "rgba(255,255,255,0.3)",
        //       ],
        //     },
        //   });
        // } else {
        map.addLayer({
          id: "feature",
          type: "fill",
          source: "feature",
          paint: {
            "fill-color": [
              "case",
              ["boolean", ["feature-state", "hover"], false],
              dataLayer.api === "coverage"
                ? "rgba(255, 255, 255, 0.8)"
                : "rgba(255,255,255,0.3)",
              "rgba(255,255,255,0.3)",
            ],
          },
        });
        // }
        map.addLayer({
          id: "feature-line",
          type: "line",
          source: "feature",
          paint: {
            "line-width": dataLayer.api === "coverage" ? 3 : 2,
            "line-color": [
              "match",
              ["get", "status"],
              "archived",
              "#EA891D",
              "#fff",
            ],
          },
        });

        if (
          dataLayer.api === "coverage" &&
          (dataLayer.type === "delineatedFields" ||
            dataLayer.type === "timeSeries")
        ) {
          feature.features && console.log(feature.features);
          const paintStyle =
            dataLayer.type === "delineatedFields"
              ? {
                  "icon-color": [
                    "match", // Use the 'match' expression: https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-match
                    ["get", "delineation_status"], // Use the result 'STORE_TYPE' property
                    "done",
                    "#01B075",
                    "in development",
                    "#FF8C00",
                    "#FF0000",
                  ],
                }
              : { "icon-color": "#01B075" };

          map.addLayer({
            id: "markers",
            type: "symbol",
            source: "feature",
            minzoom: 0,
            maxzoom: 5.4,
            layout: {
              "icon-image": "coverageMarker",
            },
            paint: paintStyle,
          });
          map.on("click", "markers", function (e) {
            console.log(e.features[0].id);

            map.getZoom() < 5.4 && map.flyTo({ center: e.lngLat, zoom: 5.4 });
          });
        } else if (
          dataLayer.api === "coverage" &&
          dataLayer.type !== "delineatedFields"
        ) {
          map.addLayer({
            id: "feature-text",
            type: "symbol",
            source: "feature",
            layout: {
              "text-field": ["get", "mgrs_tile"],
              "text-justify": "center",
              "text-anchor": "top",
              "text-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                0,
                2,
                0,
                4,
                6,
                6,
                12,
                8,
                14,
              ],
            },
            paint: {
              "text-color": "#fff",
            },
          });
          map.addLayer({
            id: "feature-link",
            type: "symbol",
            source: "feature",
            layout: {
              "text-justify": "center",
              "text-anchor": "top",
              "text-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                0,
                2,
                0,
                4,
                6,
                6,
                12,
                8,
                14,
              ],
              "text-font": ["Open Sans Bold"],
            },
            paint: {
              "text-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                "#01B075",
                "rgba(0, 152, 200, 0)",
              ],
              "text-translate": [0, -20],
            },
            filter: ["!=", ["get", "status"], "upcoming"],
          });
          map.addLayer({
            id: "feature-text2",
            type: "symbol",
            source: "feature",
            layout: {
              "text-field": [
                "format",
                "Archived",
                { "font-scale": 1.2 },
                "\n\n",
                { "font-scale": 0.4 },
                ["get", "mgrs_tile"],
                {
                  "font-scale": 0.9,
                },
              ],

              "text-justify": "center",
              "text-anchor": "top",
              "text-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                0,
                2,
                0,
                4,
                6,
                6,
                12,
                8,
                14,
              ],
              "text-font": ["Open Sans Bold"],
            },
            paint: {
              "text-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                "#EA891D",
                "rgba(0, 152, 200, 0)",
              ],
              "text-translate": [0, -20],
            },

            filter: ["==", ["get", "status"], "archived"],
          });

          map.addLayer({
            id: "feature-text3",
            type: "symbol",
            source: "feature",
            layout: {
              "text-field": [
                "format",
                "Available",
                { "font-scale": 1.2 },
                "\n\n",
                { "font-scale": 0.4 },
                ["get", "mgrs_tile"],
                {
                  "font-scale": 0.9,
                },
              ],

              "text-justify": "center",
              "text-anchor": "top",
              "text-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                0,
                0,
                2,
                0,
                4,
                6,
                6,
                12,
                8,
                14,
              ],
              "text-font": ["Open Sans Bold"],
            },
            paint: {
              "text-color": [
                "case",
                ["boolean", ["feature-state", "hover"], false],
                "#01B075",
                "rgba(0, 152, 200, 0)",
              ],
              "text-translate": [0, -20],
            },

            filter: ["==", ["get", "status"], "ready"],
          });

          map.on("mousemove", "feature", (e) => {
            const layerId = "feature";
            if (e.features.length > 0) {
              if (hoveredField) {
                map.setFeatureState(
                  { source: layerId, id: hoveredField },
                  { hover: false }
                );
              }
              const newHoveredField = e.features[0].id;
              map.setFeatureState(
                { source: "feature", id: newHoveredField },
                { hover: true }
              );
              hoveredField = newHoveredField;
            }
          });
          map.on("mouseleave", "feature", (e) => {
            if (hoveredField) {
              map.setFeatureState(
                { source: "feature", id: hoveredField },
                { hover: false }
              );
            }
            hoveredField = null;
          });
        }
        map.on("click", "feature", function (e) {
          if (
            dataLayer.api === "lowResBBOX" ||
            dataLayer.api === "lowResTile"
          ) {
            console.log(e.features);
            dispatch({
              type: setFieldId,
              payload: {
                fieldId: e.features[0].properties.id,
              },
            });

            if (dataLayer.type === "timeSeries") {
              if (map.getSource("selected-feature-timeseries")) {
                map.removeLayer("selected-feature-timeseries");
                map.removeSource("selected-feature-timeseries");
              }
              map.addSource("selected-feature-timeseries", {
                type: "geojson",
                data: {
                  ...feature,
                  features: feature.features.filter(
                    (feature) => feature.id === e.features[0].properties.id
                  ),
                },
              });

              map.addLayer({
                id: "selected-feature-timeseries",
                type: "fill",
                source: "selected-feature-timeseries",
                paint: {
                  "fill-color": "rgba(234, 137, 29, 0.8)",
                },
              });
            }
          } else if (
            mode === "draw_coverage" &&
            dataLayer.type === "delineatedFields"
          ) {
            map.getZoom() < 5.4 && map.flyTo({ center: e.lngLat, zoom: 5.4 });
          } else if (
            mode === "draw_coverage" &&
            dataLayer.type === "deepResImages"
          ) {
            // Needs to be source date.
            const availableDates =
              e.features[0].properties.availableDates &&
              JSON.parse(e.features[0].properties.availableDates);
            if (availableDates && availableDates[0].s3_cog) {
              const s2FileName = availableDates[0].s3_cog.split("//")[1];
              const center = turf.center(e.features[0].geometry);
              const s2L2AFileName = s2FileName
                .replace("/dr/", "/L2A/")
                .replace("S2_DR_T", "S2_L2A_")
                .replace("S2_DR", "S2_L2A")
                .replace("_cog.tif", "_rgb_cog.tif");
              console.log(s2FileName, s2L2AFileName);

              const fileUrl = `https://demo-time-slider.s3.eu-central-1.amazonaws.com/index.html?leftFilename=${s2L2AFileName}&rightFilename=${s2FileName}&leftNames=L2AFile&rightNames=Deeply Resolved#14/${center.geometry.coordinates[1]}/${center.geometry.coordinates[0]}`;
              window.open(fileUrl, "_blank");
            } else {
            }
          }
        });

        if (dataLayer.api === "lowResBBOX") {
          try {
            draw.deleteAll();
          } catch (e) {
            console.log(e);
          }
        }
      }
    }
  }, [feature]);

  useEffect(() => {
    if (
      draw &&
      point &&
      point.length &&
      dataLayer.drawMode === "draw_single_point"
    ) {
      let featureCollection = draw.getAll();
      featureCollection.features[0].geometry.coordinates = [
        parseFloat(point[0]),
        parseFloat(point[1]),
      ];

      draw.set(featureCollection);
    }
  }, [point]);

  // This is to set the draw rectangle on the map
  useEffect(() => {
    if (
      draw &&
      polygon &&
      polygon.length &&
      dataLayer.drawMode === "draw_rectangle"
    ) {
      let featureCollection = draw.getAll();

      !featureCollection.features.length &&
        (featureCollection.features = [
          {
            type: "Feature",
            geometry: { coordinates: [], type: "Polygon" },
            properties: {},
          },
        ]);

      featureCollection.features[0].geometry.coordinates = polygon;
      draw.set(featureCollection);
    }
  }, [polygon]);

  useEffect(() => {
    if (map && location) {
      console.log("fly to location", location);
      setLocalLocation(location);
      map.flyTo({
        center: location.center,
        zoom: location.zoom ? location.zoom : mode === "draw_coverage" ? 7 : 14,
      });
    }
  }, [location]);

  useEffect(() => {
    if (map && dataLayer && mapBaseLayer.length) {
      if (
        dataLayer.type === "delineatedFields" ||
        dataLayer.type === "timeSeries"
      ) {
        turnOnBaseMapLayers(map);
      } else {
        turnOffBaseMapLayers(map);
      }
    }
  }, [dataLayer, map, mapBaseLayer]);

  const toggleBaseLayer = () => {
    if (map.getLayer("basemap_sat")) {
      const visibiltyofSatLayer = map.getLayoutProperty(
        "basemap_sat",
        "visibility"
      );
      if (visibiltyofSatLayer === "visible") {
        map.setLayoutProperty("basemap_basic", "visibility", "visible");
        map.setLayoutProperty("basemap_sat", "visibility", "none");
      } else {
        map.setLayoutProperty("basemap_basic", "visibility", "none");
        map.setLayoutProperty("basemap_sat", "visibility", "visible");
      }
    }
  };

  useEffect(() => {
    map && map.resize();
  }, [isRightDrawerOpen]);

  useEffect(() => {
    // Maybe once we need to do this without panning. i.e immediately after the mode is changed
    console.log("dataLayer/mode changed", dataLayer);
    if (dataLayer.api === "xyz" && map && dataLayer.type === "deepResImages") {
      const tilesArray = getTilesForCurrentBounds(map);
      const tiles = [
        tilesArray.map((tile) => tile[0]),
        tilesArray.map((tile) => tile[1]),
        tilesArray[0][2],
      ];
      console.log(tilesArray);
      dispatch({
        type: setTiles,
        payload: {
          tiles: tiles,
        },
      });

      map.on("moveend", function (e) {
        console.log(e);
        if (
          dataLayer.api === "xyz" &&
          map &&
          dataLayer.type === "deepResImages"
        ) {
          const tilesArray = getTilesForCurrentBounds(map);
          console.log(tilesArray);
          const tiles = [
            tilesArray.map((tile) => tile[0]),
            tilesArray.map((tile) => tile[1]),
            tilesArray[0][2],
          ];

          dispatch({
            type: setTiles,
            payload: {
              tiles: tiles,
            },
          });
        }
      });
    }
  }, [dataLayer]);

  useEffect(() => {
    // console.log();
    if (map) {
      const geojsonSource = map.getSource("selected-feature-timeseries");
      if (map.getSource("selected-feature-timeseries") && !fieldId) {
        map.removeLayer("selected-feature-timeseries");
        map.removeSource("selected-feature-timeseries");
      }
    }
  }, [fieldId]);

  return (
    <div className="map-and-controls-container">
      <div className="map-container" ref={mapContainerRef} />
      <div className={classes.globeIconContainer}>
        <Paper>
          <IconButton onClick={toggleBaseLayer}>
            <LineGlobeIcon classes={{ root: classes.iconRoot }} />
          </IconButton>
        </Paper>
      </div>
      <div className={classes.clearButtonContainer}>
        {showClearButton && (
          <Button
            variant="contained"
            color="primary"
            className={classes.clearButton}
            onClick={() => {
              draw.deleteAll();
              dispatch({
                type: setDrawMode,
                payload: {
                  drawMode: "draw_rectangle",
                },
              });
              draw.changeMode("draw_rectangle");
            }}
          >
            Clear
          </Button>
        )}
      </div>

      <div className={classes.drawerToggleContainer}>
        <IconButton
          onClick={toggleRightDrawer}
          className={classes.collapseDrawerButton}
        >
          {isRightDrawerOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <div
        className={
          isRightDrawerOpen
            ? classes.drawerToggleMobileOpen
            : classes.drawerToggleMobileClosed
        }
      >
        <IconButton
          onClick={toggleRightDrawer}
          className={classes.collapseDrawerButtonMobile}
        >
          {isRightDrawerOpen ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </IconButton>
      </div>
    </div>
  );
};

export default Map;
