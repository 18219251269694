import { Button, makeStyles, Select, MenuItem } from "@material-ui/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { store } from "../contexts/store";
import {
  setPolygon,
  setToken,
  setTiles,
  setTileURL,
} from "../contexts/actions";
import copyToClipboard from "../util/copyToClipboard";

import { domain, api_version } from "../config";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputField from "./InputField";
import Disclaimer from "./Disclaimer";

const useStyles = makeStyles({
  info: {
    padding: "24px 20px",
    boxSizing: "border-box",
    color: "#949EA7",
    backgroundColor: "#fff",
    marginBottom: 8,
    "& h3": {
      lineHeight: "24px",
      marginBottom: 8,
      color: "#1D2023",
    },
    "& h4": {
      color: "#1D2023",
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 600,
      margin: 0,
    },
    "& h6": {
      color: "#1D2023",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
      margin: 0,
    },
  },
  iconRoot: {
    fill: "none",
  },
  mt8: {
    marginTop: 8,
  },
  flex: {
    display: "flex",
  },
  flex1: {
    flex: 1,
    wordWrap: "anywhere",
  },
  rightContainer: {
    flex: 1,
    wordWrap: "anywhere",
    color: "#1D2023",
    "& h6": {
      marginBottom: 16,
    },
  },
  responseContainer: {
    display: "flex",
    marginBottom: 16,
    fontWeight: 700,
    maxWidth: "100%",
  },
  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: "20px",
  },
  inputRoot: {
    borderRadius: 4,
  },
  generateResp: {
    marginTop: 16,
    textTransform: "capitalize",
  },
  url: {
    color: "#EA891D",
  },
  copyButton: {
    marginRight: 4,
    textTransform: "capitalize",
    borderRadius: 4,
    marginTop: 8,
    "@media (max-width: 600px)": {
      position: "static",
      marginBottom: 8,
    },
  },
  textFields: {
    display: "flex",
    marginTop: 12,
  },
  parameters: {
    marginTop: "24px !important",
  },
  imageGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(68px, 1fr))",
    columnGap: "16px",
    rowGap: "16px",
  },
  drImageThumbnail: {
    width: "100%",
    borderRadius: 4,
    border: "1px solid #EEEFF1",
  },
  rasterUrlContainer: {
    position: "relative",
    display: "flex",
    marginTop: 16,
  },
  rasterUrl: {
    color: "#1D2023",
    fontWeight: 600,
    maxWidth: "100%",
    wordWrap: "anywhere",
    marginRight: 8,
  },
  rasterUrlLabel: {
    fontSize: 12,
    marginBottom: 8,
  },
});

const baseUrl = `${domain}/${api_version}`;
//To do: Needs to be changed when promoting to production
const DRUrl = "https://api.digifarm.io/development/s2/crop/";

const DRBboxDrawer = (props) => {
  const classes = useStyles();

  const {
    state: { drawMode: mapDrawMode, tile, fieldId, token, tiles, polygon },
    dispatch,
  } = useContext(store);

  const [responseStatus, setResponseStatus] = useState();
  const [startDate, setStartDate] = React.useState(new Date("2019-01-01"));
  const [endDate, setEndDate] = React.useState(new Date());
  // Need to set it from locationObject[region].mgrs
  const [mgrsTiles, setMgrsTiles] = useState();
  const [imageType, setImageType] = useState("rgbn_rgb");
  const [imageFormat, setImageFormat] = useState("png");
  const [bBoxPoints, setBBoxPoints] = useState([]);

  const [requestUrl, setRequestUrl] = useState("");
  const [imageBlobUrl, setImageBlobUrl] = useState("");
  const [blobSize, setBlobSize] = useState(0);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    if (polygon && polygon[0] && polygon[0].length) {
      let maxCallback = (max, cur) =>
        Math.max(parseFloat(max), parseFloat(cur));
      let minCallback = (max, cur) =>
        Math.min(parseFloat(max), parseFloat(cur));

      const minLng = polygon[0]
        .map((point) => point[0])
        .reduce(minCallback, Infinity);
      const maxLng = polygon[0]
        .map((point) => point[0])
        .reduce(maxCallback, -Infinity);

      const minLat = polygon[0]
        .map((point) => point[1])
        .reduce(minCallback, Infinity);
      const maxLat = polygon[0]
        .map((point) => point[1])
        .reduce(maxCallback, -Infinity);

      setBBoxPoints([minLng, minLat, maxLng, maxLat]);
    }
  }, [polygon]);

  const getFormattedDate = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return `${yyyy}${mm}${dd}`;
  };

  const copyContent = (responseJson) => {
    copyToClipboard(JSON.stringify(responseJson));
  };

  const handleClick = () => {
    const fetchUrl =
      DRUrl +
      bBoxPoints.join(",") +
      `.${imageFormat}?token=${token}&render=${imageType}${
        mgrsTiles ? `&mgrs_t=${mgrsTiles}` : ""
      }&start_date=${getFormattedDate(startDate)}&end_date=${getFormattedDate(
        endDate
      )}`;

    const rectangle = [
      [
        [bBoxPoints[0], bBoxPoints[3]],
        [bBoxPoints[2], bBoxPoints[3]],
        [bBoxPoints[2], bBoxPoints[1]],
        [bBoxPoints[0], bBoxPoints[1]],
        [bBoxPoints[0], bBoxPoints[3]],
      ],
    ];

    if (polygon && polygon.length) {
      if (!comparePolygonArrays(rectangle[0], polygon[0])) {
        dispatch({
          type: setPolygon,
          payload: {
            polygon: rectangle,
          },
        });
      }
    }

    setRequestUrl(fetchUrl);

    dispatch({
      type: setTileURL,
      payload: {
        tileURL: `${fetchUrl}`,
      },
    });

    fetch(fetchUrl)
      .then(function (response) {
        setResponseStatus(response.status);
        return response.blob();
      })
      .then(function (imageBlob) {
        setBlobSize(imageBlob.size);
        const objectURL = URL.createObjectURL(imageBlob);
        setImageBlobUrl(objectURL);
      });
  };

  const comparePolygonArrays = (array1, array2) => {
    return array1.every(
      (value, index) =>
        value[0] == array2[index][0] && value[1] == array2[index][1]
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h3 className={classes.parameters}>Parameters</h3>

        <InputField
          label="Client Token provided by Digifarm and EarthDaily Analytics"
          value={token}
          onChange={(e) => {
            dispatch({
              type: setToken,
              payload: {
                token: e.target.value,
              },
            });
          }}
        ></InputField>

        <div className={classes.textFields}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="filled"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                },
              }}
              format="dd/MM/yyyy"
              margin="normal"
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              className="mr-12"
            />
            <KeyboardDatePicker
              disableToolbar
              inputVariant="filled"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                },
              }}
              format="dd/MM/yyyy"
              margin="normal"
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>

      <div className={classes.info}>
        <InputField
          label="mgrs_tiles"
          value={mgrsTiles}
          onChange={(e) => {
            setMgrsTiles(e.target.value);
          }}
        ></InputField>

        <div className={classes.textFields}>
          <InputField
            label="Location in format of minLongitude"
            defaultValue=""
            value={bBoxPoints && bBoxPoints[0]}
            className="mr-12"
            onChange={(e) => {
              setBBoxPoints([
                parseFloat(e.target.value),
                bBoxPoints[1],
                bBoxPoints[2],
                bBoxPoints[3],
              ]);
            }}
          ></InputField>
          <InputField
            label="Location in format of minLatitude"
            defaultValue=""
            value={bBoxPoints && bBoxPoints[1]}
            onChange={(e) => {
              setBBoxPoints([
                bBoxPoints[0],
                parseFloat(e.target.value),
                bBoxPoints[2],
                bBoxPoints[3],
              ]);
            }}
          ></InputField>
        </div>
        <div className={classes.textFields}>
          <InputField
            label="Location in format of maxLongitude"
            defaultValue=""
            value={bBoxPoints && bBoxPoints[2]}
            className="mr-12"
            onChange={(e) => {
              setBBoxPoints([
                bBoxPoints[0],
                bBoxPoints[1],
                parseFloat(e.target.value),
                bBoxPoints[3],
              ]);
            }}
          ></InputField>
          <InputField
            label="Location in format of maxLatitude"
            defaultValue=""
            value={bBoxPoints && bBoxPoints[3]}
            onChange={(e) => {
              setBBoxPoints([
                bBoxPoints[0],
                bBoxPoints[1],
                bBoxPoints[2],
                parseFloat(e.target.value),
              ]);
            }}
          ></InputField>
        </div>

        <div className={classes.textFields}>
          <InputField
            id="select"
            label="Image Type"
            value={imageType}
            onChange={(event) => {
              setImageType(event.target.value);
            }}
            select
            className="mr-12"
          >
            <MenuItem value="rgbn_rgb">RGB</MenuItem>
            <MenuItem value="rgbn_evi">EVI</MenuItem>
            <MenuItem value="rgbn_ndvi">NDVI</MenuItem>
          </InputField>
          <InputField
            id="select"
            label="Image Format"
            value={imageFormat}
            onChange={(event) => {
              setImageFormat(event.target.value);
            }}
            select
          >
            <MenuItem value="png">PNG</MenuItem>
            <MenuItem value="jpg">JPG</MenuItem>
            {/* <MenuItem value="tif">TIF</MenuItem> */}
          </InputField>
        </div>
        <Disclaimer />

        {requestUrl && (
          <div className={classes.rasterUrlContainer}>
            <div>
              <div className={classes.rasterUrlLabel}>Raster Tile Url</div>
              <div className={classes.rasterUrl}>{requestUrl}</div>
            </div>

            <div>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  copyContent(
                    `${DRUrl}/{z}/{x}/{y}.${imageFormat}?token=${token}&render=${imageType}${
                      mgrsTiles ? `&mgrs_t=${mgrsTiles}` : ""
                    }&start_date=${getFormattedDate(
                      startDate
                    )}&end_date=${getFormattedDate(endDate)}`
                  );
                }}
                className={classes.copyButton}
              >
                Copy
              </Button>
            </div>
          </div>
        )}

        <div className={classes.textFields}></div>
        <Button
          variant={"contained"}
          color="primary"
          onClick={handleClick}
          fullWidth={true}
          className={classes.generateResp}
        >
          Generate Response
        </Button>
      </div>

      {responseStatus &&
      (responseStatus !== 200 || parseInt(blobSize) < 2000) ? (
        <div className={classes.info}>
          <p>
            Please check the coverage API to see if we have data for the region
            you are querying. The test token allows you to query to the DR API
            for only a sample area of each region. If you wish to query outside
            the sample region shown get in touch with us at hello@digifarm.io
          </p>
        </div>
      ) : (
        ""
      )}
      <div className={classes.info}>
        <div className={classes.imageGrid}>
          {requestUrl && (
            <div>
              <img
                className={classes.drImageThumbnail}
                src={imageBlobUrl}
                alt="Deeply resolved B box area"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DRBboxDrawer;
