import { Button, makeStyles } from "@material-ui/core";
import copyToClipboard from "../util/copyToClipboard";
import { saveAs } from "file-saver";

const useStyles = makeStyles({
  mt8: {
    marginTop: 8,
  },

  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: "20px",
  },
  responseJsonContainer: {
    position: "relative",
  },
  responseJson: {
    backgroundColor: "#EEEFF1",
    borderRadius: 4,
    padding: "8px 16px",
    maxHeight: 520,
    overflowY: "scroll",
    maxWidth: 440,
    "& pre": {
      maxWidth: "100%",
    },
  },

  copyButton: {
    position: "absolute",
    top: 16,
    right: 16,
    textTransform: "capitalize",
    borderRadius: 4,
    backgroundColor: "#E5F7F1",
    "@media (max-width: 600px)": {
      position: "static",
      marginBottom: 8,
    },
    "&:hover": {
      backgroundColor: "#E5F7F1",
    },
  },
  downloadButton: {
    position: "absolute",
    top: 60,
    right: 16,
    textTransform: "capitalize",
    borderRadius: 4,
    backgroundColor: "#E5F7F1",
    "@media (max-width: 600px)": {
      position: "static",
      marginBottom: 8,
    },
    "&:hover": {
      backgroundColor: "#E5F7F1",
    },
  },
});

const ResponseDisplay = (props) => {
  const classes = useStyles();
  const { responseJson, responseStatus } = props;

  const copyContent = () => {
    copyToClipboard(JSON.stringify(responseJson));
  };
  const downloadGeoJSON = () => {
    const blob = new Blob([JSON.stringify(responseJson, null, 2)], {
      type: "application/json",
    });
    saveAs(blob, "Response.json");
  };

  return (
    <div className={classes.responseJsonContainer}>
      <div className={classes.responseJson}>
        {responseStatus === 200 && (
          <>
            <Button
              variant="outlined"
              color="primary"
              onClick={copyContent}
              className={classes.copyButton}
            >
              Copy to clipboard
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={downloadGeoJSON}
              className={classes.downloadButton}
            >
              Save as GeoJSON
            </Button>
          </>
        )}
        {responseJson && <pre>{JSON.stringify(responseJson, null, 2)}</pre>}
      </div>
    </div>
  );
};

export default ResponseDisplay;
