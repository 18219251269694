import { Checkbox, FormControlLabel, makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const useStyles = makeStyles((theme) => ({
  info: {
    padding: "24px 20px",
    boxSizing: "border-box",
    color: "#949EA7",
    backgroundColor: "#fff",
    marginBottom: 8,
  },
  checkboxLabel: {
    color: "inherit",
    fontSize: 12,
    fontWeight: 600,
    width: 36,
    marginRight: 0,
  },
  graphLabel: {
    fontSize: 12,
    fontWeight: 700,
    color: "#949EA7",
  },
  ndviEviCheckboxContainer: {
    width: 126,
  },
}));

var months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const TimeSeriesChart = ({ data }) => {
  const classes = useStyles();
  const [bands, setBands] = useState([
    { name: "B01", active: true },
    { name: "B02", active: true },
    { name: "B03", active: true },
    { name: "B04", active: true },
    { name: "B05", active: true },
    { name: "B06", active: true },
    { name: "B07", active: true },
    { name: "B08", active: true },
    { name: "B08A", active: true },
    { name: "B09", active: true },
    { name: "B11", active: true },
    { name: "B12", active: true },
  ]);

  const [render, setRender] = useState([
    { name: "EVI", active: true },
    { name: "NDVI", active: true },
    { name: "NDMI", active: true },
    { name: "MI", active: true },
  ]);

  const [graphData, setGraphData] = useState({});
  // format data.
  // Extract unique dates and then add the individual values to it

  useEffect(() => {
    try {
      if (data) {
        // console.log("keys", Object.keys(data.names));
        const keys = Object.keys(data.names);
        const arryaOfObjects = Object.keys(data.names).map(
          (key) => data.names[key].values
        );
        var flattenedArray = arryaOfObjects.reduce(
          (r, e) => (r.push(...e), r),
          []
        );

        const uniqueDates = flattenedArray
          .map((item) => item.date)
          .filter((value, index, self) => self.indexOf(value) === index)
          .sort((date1, date2) => date1 - date2);
        console.log(uniqueDates);

        const graphData = uniqueDates.map((date) => {
          const graphEntry = { date };
          keys.forEach((key) => {
            const dateMatchingObj = data.names[key].values.find(
              (elem) => elem.date === date
            );
            if (dateMatchingObj) {
              graphEntry[key] = dateMatchingObj.value;
              // const dateObj = new Date(date);
              graphEntry["date"] = date;
              graphEntry["formattedDate"] = `${date.substring(6)} ${
                months[parseInt(date.substring(4, 6)) - 1]
              } ${date.substring(0, 4)}`;

              graphEntry["unixTime"] = Date.parse(
                `${date.substring(6)} ${
                  months[parseInt(date.substring(4, 6)) - 1]
                } ${date.substring(0, 4)}`
              );
            }
          });
          return graphEntry;
        });
        // .sort(({ date: date1 }, { date: date2 }) => date1 - date2);
        console.log("ddg", graphData);
        setGraphData(graphData);
      }
    } catch (e) {
      console.log(e);
    }
  }, [data]);

  // Get unique dates

  const colors = {
    B01: "#33A5C9",
    B02: "#E8C242",
    B03: "#D81B1D",
    B04: "#BCDB78",
    B05: "#9CD1C7",
    B06: "#CC4794",
    B07: "#86C3AA",
    B08: "#949EA7",
    B08A: "#BCDB78",
    B09: "#8DF097",
    B11: "#9CD1C7",
    B12: "#B493F8",
    EVI: "#01B075",
    NDVI: "#EA891D",
    NDMI: "#33A5C9",
    MI: "#E8C242",
  };
  const formatXAxis = (value) => {
    const date = new Date(value);

    return `${date.getDate(6)} ${
      months[parseInt(date.getMonth(4, 6))]
    } ${date.getFullYear()}`;
  };

  const RenderItemCheckbox = ({ band }) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked
            checked={band.active}
            sx={{
              color: "#636A72",
              "&.Mui-checked": {
                color: "#636A72",
              },
            }}
            color="default"
            size="small"
          />
        }
        label={band.name}
        style={{
          color: colors[band.name],
          fontSize: "12px",
          width: 60,
        }}
        name={band.name}
        classes={{
          label: classes.checkboxLabel,
        }}
        onChange={(e) => {
          setRender(
            render.map((band) =>
              band.name === e.target.name
                ? {
                    ...band,
                    active: e.target.checked,
                  }
                : band
            )
          );
        }}
      />
    );
  };

  return (
    <div>
      <div className={classes.graphLabel}>EVI, NDVI, NDMI,MI</div>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          width={500}
          height={200}
          data={graphData}
          syncId="anyId"
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid />

          <Tooltip
            wrapperStyle={{
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              zIndex: 2,
            }}
            contentStyle={{
              border: "1px solid #D4D8DC",
              borderRadius: "8px",
              zIndex: 2,
            }}
            labelFormatter={formatXAxis}
          />

          <XAxis
            dataKey="unixTime"
            stroke="#fff"
            type="number"
            tickFormatter={formatXAxis}
            domain={[
              Date.parse(graphData[0]),
              Date.parse(graphData[graphData.length - 1]),
            ]}
          />

          {render.map((renderItem) =>
            renderItem.active ? (
              <Line
                type="monotone"
                dataKey={renderItem.name}
                stroke={colors[renderItem.name]}
                dot={false}
                // fill={colors[renderItem.name]}
              />
            ) : (
              ""
            )
          )}
          <YAxis
            stroke="#EEEFF1"
            tick={{ fill: "#949EA7", strokeWidth: 1 }}
            orientation="right"
          />
        </LineChart>
      </ResponsiveContainer>
      <div className={classes.graphLabel}>S2 BANDS</div>
      <ResponsiveContainer width="100%" height={200}>
        <LineChart
          width={500}
          height={200}
          data={graphData}
          syncId="anyId"
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid />
          <XAxis
            dataKey="unixTime"
            stroke="#949EA7"
            tickFormatter={formatXAxis}
            type="number"
            domain={[
              Date.parse(graphData[0]),
              Date.parse(graphData[graphData.length - 1]),
            ]}
          />

          <Tooltip
            wrapperStyle={{
              boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.12)",
              borderRadius: "8px",
              zIndex: 2,
            }}
            contentStyle={{
              border: "1px solid #D4D8DC",
              borderRadius: "8px",
              zIndex: 2,
            }}
            labelFormatter={formatXAxis}
          />

          {bands.map((renderItem) =>
            renderItem.active ? (
              <Line
                type="monotone"
                dataKey={renderItem.name}
                stroke={colors[renderItem.name]}
                dot={false}
                // fill={colors[renderItem.name]}
              />
            ) : (
              ""
            )
          )}
          <YAxis
            stroke="#EEEFF1"
            tick={{ fill: "#949EA7", strokeWidth: 1 }}
            orientation="right"
          />
        </LineChart>
      </ResponsiveContainer>
      <div>
        <div className="flex">
          <div className={classes.ndviEviCheckboxContainer}>
            <div className="flex">
              {render
                .filter((band) => band.name === "EVI" || band.name === "NDVI")
                .map((band) => (
                  <RenderItemCheckbox band={band} />
                ))}
            </div>
            <div className="flex">
              {render
                .filter((band) => band.name === "NDMI" || band.name === "MI")
                .map((band) => (
                  <RenderItemCheckbox band={band} />
                ))}
            </div>
          </div>
          <div>
            {bands.map((band) => (
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    checked={band.active}
                    sx={{
                      color: "#636A72",
                      "&.Mui-checked": {
                        color: "#636A72",
                      },
                    }}
                    color="default"
                    size="small"
                  />
                }
                label={band.name}
                style={{
                  color: colors[band.name],
                  width: 60,
                }}
                name={band.name}
                classes={{
                  label: classes.checkboxLabel,
                }}
                onChange={(e) => {
                  console.log(bands);
                  setBands(
                    bands.map((band) =>
                      band.name === e.target.name
                        ? {
                            ...band,
                            active: e.target.checked,
                          }
                        : band
                    )
                  );
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSeriesChart;
