import { Button, makeStyles, Select, MenuItem } from "@material-ui/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { store } from "../contexts/store";
import {
  setPolygon,
  setToken,
  setTiles,
  setTileURL,
} from "../contexts/actions";
import copyToClipboard from "../util/copyToClipboard";

import { VectorTile } from "@mapbox/vector-tile";
import { domain, api_version } from "../config";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
// import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import InputField from "./InputField";
import Disclaimer from "./Disclaimer";

const useStyles = makeStyles({
  info: {
    padding: "24px 20px",
    boxSizing: "border-box",
    color: "#949EA7",
    backgroundColor: "#fff",
    marginBottom: 8,
    "& h3": {
      lineHeight: "24px",
      marginBottom: 8,
      color: "#1D2023",
    },
    "& h4": {
      color: "#1D2023",
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 600,
      margin: 0,
    },
    "& h6": {
      color: "#1D2023",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
      margin: 0,
    },
  },
  iconRoot: {
    fill: "none",
  },
  mt8: {
    marginTop: 8,
  },
  flex: {
    display: "flex",
  },
  flex1: {
    flex: 1,
    wordWrap: "anywhere",
  },
  rightContainer: {
    flex: 1,
    wordWrap: "anywhere",
    color: "#1D2023",
    "& h6": {
      marginBottom: 16,
    },
  },
  responseContainer: {
    display: "flex",
    marginBottom: 16,
    fontWeight: 700,
    maxWidth: "100%",
  },
  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: "20px",
  },
  responseJson: {
    backgroundColor: "#EEEFF1",
    borderRadius: 4,
    padding: "8px 16px",
    position: "relative",
    maxWidth: 420,
    minHeight: 100,
    maxHeight: 520,
    overflowY: "scroll",
  },
  inputRoot: {
    borderRadius: 4,
  },
  generateResp: {
    marginTop: 16,
    textTransform: "capitalize",
  },
  url: {
    color: "#EA891D",
  },
  copyButton: {
    marginRight: 8,
    textTransform: "capitalize",
    borderRadius: 4,
    "@media (max-width: 600px)": {
      position: "static",
      marginBottom: 8,
    },
  },
  textFields: {
    display: "flex",
    marginTop: 12,
  },
  parameters: {
    marginTop: "24px !important",
  },
  imageGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, minmax(76px, 1fr))",
    columnGap: "16px",
    rowGap: "16px",
    fontSize: "10px",
  },
  drImageThumbnail: {
    width: "100%",
    borderRadius: 4,
    marginTop: 4,
    border: "1px solid #E0E0E0",
  },
  rasterUrlContainer: {
    position: "relative",
    display: "flex",
    marginTop: 16,
  },
  rasterUrl: {
    color: "#1D2023",
    fontWeight: 600,
    maxWidth: "100%",
    wordWrap: "anywhere",
  },
  rasterUrlLabel: {
    fontSize: 12,
    marginBottom: 8,
  },
});

const baseUrl = `${domain}/${api_version}`;
//To do: Needs to be changed when promoting to production
const DRUrl = "https://api.digifarm.io/development/s2/tiles";

const isTileLoaded = new Array(64).fill(false);

const DRTileDrawer = (props) => {
  const classes = useStyles();

  const {
    state: { drawMode: mapDrawMode, tile, token, tiles },
    dispatch,
  } = useContext(store);

  const [tileCoordinates, setTileCoordinates] = useState([]);

  useEffect(() => {
    if (tile && tile.length) {
      setTileCoordinates(tile);
    }
  }, [tile]);

  const [responseJson, setResponseJson] = useState();
  const [responseStatus, setResponseStatus] = useState();
  const [startDate, setStartDate] = React.useState(new Date("2019-01-01"));
  const [endDate, setEndDate] = React.useState(new Date());
  // Need to set it from locationObject[region].mgrs
  const [mgrsTiles, setMgrsTiles] = useState();
  const [imageType, setImageType] = useState("rgbn_rgb");
  const [imageFormat, setImageFormat] = useState("png");
  const [responseClicked, setResponseClicked] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [isAtleastOneImageLoaded, setIsAtleastOneImageLoaded] = useState(false);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleClick = () => {
    // Although I can just add the image component with the generated urls, what happens when there is an error? The error will need to be shown properly/at least the user needs to know about it
    // I think I need to add a loading state to the image component and show a loading spinner while the image is loading
    // I also need to add a fallback image if the image is not found
    dispatch({
      type: setTileURL,
      payload: {
        tileURL: `${DRUrl}/{z}/{x}/{y}.${
          imageFormat === "tif" ? "png" : imageFormat
        }?token=${token}&render=${imageType}${
          mgrsTiles ? `&mgrs_t=${mgrsTiles}` : ""
        }&start_date=${getFormattedDate(startDate)}&end_date=${getFormattedDate(
          endDate
        )}`,
      },
    });
    setResponseClicked(true);
    const tileUrls = tiles[0].map(
      (tile, index) =>
        `${DRUrl}/${tiles[2]}/${tiles[0][index]}/${
          tiles[1][index]
        }.${imageFormat}?token=${token}&render=${imageType}${
          mgrsTiles ? `&mgrs_t=${mgrsTiles}` : ""
        }&start_date=${getFormattedDate(startDate)}&end_date=${getFormattedDate(
          endDate
        )}`
    );

    setImageUrls(tileUrls);

    fetchOneTile(tileUrls[0]);
  };

  const getFormattedDate = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();
    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return `${yyyy}${mm}${dd}`;
  };

  const copyContent = (responseJson) => {
    copyToClipboard(JSON.stringify(responseJson));
  };

  const fetchOneTile = async (tileUrl) => {
    console.log(tileUrl);
    fetch(tileUrl).then((response) => {
      console.log("response -fetch one tile", response);
      setResponseStatus(response.status);
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h3 className={classes.parameters}>Parameters</h3>

        <InputField
          label="Client Token provided by Digifarm and EarthDaily Analytics"
          value={token}
          onChange={(e) => {
            dispatch({
              type: setToken,
              payload: {
                token: e.target.value,
              },
            });
          }}
        ></InputField>

        <div className={classes.textFields}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              inputVariant="filled"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                },
              }}
              format="dd/MM/yyyy"
              margin="normal"
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              className="mr-12"
            />
            <KeyboardDatePicker
              disableToolbar
              inputVariant="filled"
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.inputRoot,
                },
              }}
              format="dd/MM/yyyy"
              margin="normal"
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>

      <div className={classes.info}>
        <InputField
          label="mgrs_tiles"
          value={mgrsTiles}
          onChange={(e) => {
            setMgrsTiles(e.target.value);
          }}
        ></InputField>

        <div className={classes.textFields}>
          <InputField
            label="z"
            value={tiles && tiles[2]}
            onChange={(e) => {
              setTileCoordinates([
                tileCoordinates[0],
                tileCoordinates[1],
                parseFloat(e.target.value),
              ]);
            }}
            className="mr-12"
          ></InputField>
          <InputField
            label="x"
            value={tiles && tiles[0].join(",")}
            onChange={(e) => {
              dispatch({
                type: setTiles,
                payload: {
                  tiles: [e.target.value.split(","), tiles[1], tiles[2]],
                },
              });
            }}
            className="mr-12"
          ></InputField>
          <InputField
            label="y"
            value={tiles && tiles[1].join(",")}
            onChange={(e) => {
              dispatch({
                type: setTiles,
                payload: {
                  tiles: [tiles[0], e.target.value.split(","), tiles[2]],
                },
              });
            }}
          ></InputField>
        </div>

        <div className={classes.textFields}>
          <InputField
            id="select"
            label="Image Type"
            value={imageType}
            onChange={(event) => {
              setImageType(event.target.value);
            }}
            select
            className="mr-12"
          >
            <MenuItem value="rgbn_rgb">RGB</MenuItem>
            <MenuItem value="rgbn_evi">EVI</MenuItem>
            <MenuItem value="rgbn_ndvi">NDVI</MenuItem>
          </InputField>
          <InputField
            id="select"
            label="Image Format"
            value={imageFormat}
            onChange={(event) => {
              setImageFormat(event.target.value);
            }}
            select
          >
            <MenuItem value="png">PNG</MenuItem>
            <MenuItem value="jpg">JPG</MenuItem>
            <MenuItem value="tif">TIF</MenuItem>
            {/* <MenuItem value="tif">TIF</MenuItem> */}
          </InputField>
        </div>

        <div className={classes.rasterUrlContainer}>
          <div>
            <div className={classes.rasterUrlLabel}>Raster Tile Url</div>
            <div className={classes.rasterUrl}>
              {`${DRUrl}/{z}/{x}/{y}.${imageFormat}?token=${token}&render=${imageType}${
                mgrsTiles ? `&mgrs_t=${mgrsTiles}` : ""
              }&start_date=${getFormattedDate(
                startDate
              )}&end_date=${getFormattedDate(endDate)}`}
            </div>
          </div>
          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                copyContent(
                  `${DRUrl}/{z}/{x}/{y}.${imageFormat}?token=${token}&render=${imageType}${
                    mgrsTiles ? `&mgrs_t=${mgrsTiles}` : ""
                  }&start_date=${getFormattedDate(
                    startDate
                  )}&end_date=${getFormattedDate(endDate)}`
                );
              }}
              className={classes.copyButton}
            >
              Copy
            </Button>
          </div>
        </div>

        <div className={classes.textFields}></div>
        <Button
          variant={"contained"}
          color="primary"
          onClick={handleClick}
          fullWidth={true}
          className={classes.generateResp}
        >
          Generate Response
        </Button>
      </div>
      {responseStatus &&
        responseClicked &&
        responseStatus !== 200 &&
        !isTileLoaded.filter((value) => value === true).length && (
          <>
            <div className={classes.info}>
              <h3>Response</h3>
              <div className={classes.responseContainer}>
                <div className={classes.responseCode}>{responseStatus}</div>
                <div className={classes.rightContainer}>
                  <h6>
                    {responseStatus === 404 ? (
                      "DR image not found at the location"
                    ) : (
                      <>
                        "Response Status": {responseStatus} <br />
                        <br />
                      </>
                    )}
                  </h6>
                  <p>
                    Please check the coverage API to see if we have data for the
                    region you are querying. The test token allows you to query
                    to the DR API for only a sample area of each region. If you
                    wish to query outside the sample region shown get in touch
                    with us at hello@digifarm.io
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      <div className={classes.info}>
        <div className={classes.imageGrid}>
          {imageUrls && responseClicked
            ? imageUrls.map((tileUrl, index) => (
                <div>
                  {/* Need to show the tile number label */}
                  {/* Alt should also preferably be the same */}
                  {tiles &&
                    tiles[2] + "/" + tiles[0][index] + "/" + tiles[1][index]}
                  <img
                    style={isAtleastOneImageLoaded ? {} : { display: "none" }}
                    className={classes.drImageThumbnail}
                    src={tileUrl}
                    onLoad={() => {
                      !isAtleastOneImageLoaded &&
                        setIsAtleastOneImageLoaded(true);
                    }}
                  />
                </div>
              ))
            : ""}
        </div>
      </div>
    </div>
  );
};

export default DRTileDrawer;
