import "./App.css";
import MapPage from "./pages/map";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
} from "@material-ui/core/styles";
import { StateProvider } from "./contexts/store";
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "Inter",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      light: "#CCEFE3",
      main: "#01B075",
      dark: "#017951",
      contrastText: "#fff",
    },
    text: {
      primary: "#1D2023",
      secondary: "#949EA7",
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiMenuItem: {
      root: {
        fontWeight: 600,
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <StateProvider>
        <div className="App">
          <MapPage />
        </div>
      </StateProvider>
    </ThemeProvider>
  );
}

export default App;
