// import SphericalMercator from "@mapbox/sphericalmercator";
import tilebelt from "@mapbox/tilebelt";

const params = new URLSearchParams(window.location.search);

function addBaseMapLayers(map, mapBaseLayerList) {
  mapBaseLayerList.forEach((mapBaseLayer) => {
    map.addLayer(mapBaseLayer);
  });
}

function turnOffBaseMapLayers(map) {
  // do this to all layers
  map.setLayoutProperty("basemap_sat2", "visibility", "none");
  map.setLayoutProperty("iowa_sioux_basemap", "visibility", "none");
  map.setLayoutProperty("punjab_india_basemap", "visibility", "none");
  map.setLayoutProperty("czech_basemap", "visibility", "none");
  map.setLayoutProperty("brazil_basemap", "visibility", "none");
  map.setLayoutProperty("poland_basemap", "visibility", "none");
  map.setLayoutProperty("punjab_43rfq_basemap", "visibility", "none");
  // map.setLayoutProperty("norway_basemap", "visibility", "none");
  map.setLayoutProperty("germany_basemap", "visibility", "none");
  map.setLayoutProperty("belgium_basemap", "visibility", "none");
  map.setLayoutProperty("austria_basemap", "visibility", "none");
  map.setLayoutProperty("italy_basemap", "visibility", "none");
  map.setLayoutProperty("milan_basemap", "visibility", "none");
  map.setLayoutProperty("tulare_basemap", "visibility", "none");
  map.setLayoutProperty("saskatchewan_basemap", "visibility", "none");
  map.setLayoutProperty("switzerland_basemap", "visibility", "none");
  map.setLayoutProperty("denmark_basemap", "visibility", "none");
  map.setLayoutProperty("suphanburi_basemap", "visibility", "none");
  map.setLayoutProperty("france_basemap", "visibility", "none");
  map.setLayoutProperty("hungary_basemap", "visibility", "none");
  map.setLayoutProperty("england_basemap", "visibility", "none");
  map.setLayoutProperty("bulgaria_basemap", "visibility", "none");
  map.setLayoutProperty("netherlands_basemap", "visibility", "none");
  map.setLayoutProperty("mexico_basemap", "visibility", "none");
  map.setLayoutProperty("mp_india_43qdf_basemap", "visibility", "none");
  map.setLayoutProperty("varva_ua_basemap", "visibility", "none");
  map.setLayoutProperty("ridhora_in_basemap", "visibility", "none");
  map.setLayoutProperty("austria_kraxenberg_basemap", "visibility", "none");
  map.setLayoutProperty("oxford_uk_basemap", "visibility", "none");
  map.setLayoutProperty("cupar_ca_basemap", "visibility", "none");
  map.setLayoutProperty("kamenca_pl_basemap", "visibility", "none");
  map.setLayoutProperty("soltepec_mx_basemap", "visibility", "none");
  map.setLayoutProperty("pedrola_es_basemap", "visibility", "none");
  map.setLayoutProperty("ban_nong_kratum_th_basemap", "visibility", "none");
  map.setLayoutProperty("toulouse_fr_basemap", "visibility", "none");
  map.setLayoutProperty("aramiro_nz_basemap", "visibility", "none");
  map.setLayoutProperty("mekong_vn_basemap", "visibility", "none");
}
function turnOnBaseMapLayers(map) {
  // do this to all layers
  map.setLayoutProperty("basemap_sat2", "visibility", "visible");
  map.setLayoutProperty("iowa_sioux_basemap", "visibility", "visible");
  map.setLayoutProperty("punjab_india_basemap", "visibility", "visible");
  map.setLayoutProperty("czech_basemap", "visibility", "visible");
  map.setLayoutProperty("brazil_basemap", "visibility", "visible");
  map.setLayoutProperty("poland_basemap", "visibility", "visible");
  map.setLayoutProperty("punjab_43rfq_basemap", "visibility", "visible");
  // map.setLayoutProperty("norway_basemap", "visibility", "visible");
  map.setLayoutProperty("germany_basemap", "visibility", "visible");
  map.setLayoutProperty("belgium_basemap", "visibility", "visible");
  map.setLayoutProperty("austria_basemap", "visibility", "visible");
  map.setLayoutProperty("italy_basemap", "visibility", "visible");
  map.setLayoutProperty("milan_basemap", "visibility", "visible");
  map.setLayoutProperty("tulare_basemap", "visibility", "visible");
  map.setLayoutProperty("saskatchewan_basemap", "visibility", "visible");
  map.setLayoutProperty("switzerland_basemap", "visibility", "visible");
  map.setLayoutProperty("denmark_basemap", "visibility", "visible");
  map.setLayoutProperty("suphanburi_basemap", "visibility", "visible");
  map.setLayoutProperty("france_basemap", "visibility", "visible");
  map.setLayoutProperty("hungary_basemap", "visibility", "visible");
  map.setLayoutProperty("england_basemap", "visibility", "visible");
  map.setLayoutProperty("bulgaria_basemap", "visibility", "visible");
  map.setLayoutProperty("netherlands_basemap", "visibility", "visible");
  map.setLayoutProperty("mexico_basemap", "visibility", "visible");
  map.setLayoutProperty("mp_india_43qdf_basemap", "visibility", "visible");
  map.setLayoutProperty("varva_ua_basemap", "visibility", "visible");
  map.setLayoutProperty("ridhora_in_basemap", "visibility", "visible");
  map.setLayoutProperty("austria_kraxenberg_basemap", "visibility", "visible");
  map.setLayoutProperty("oxford_uk_basemap", "visibility", "visible");
  map.setLayoutProperty("cupar_ca_basemap", "visibility", "visible");
  map.setLayoutProperty("kamenca_pl_basemap", "visibility", "visible");
  map.setLayoutProperty("soltepec_mx_basemap", "visibility", "visible");
  map.setLayoutProperty("pedrola_es_basemap", "visibility", "visible");
  map.setLayoutProperty("ban_nong_kratum_th_basemap", "visibility", "visible");
  map.setLayoutProperty("toulouse_fr_basemap", "visibility", "visible");
  map.setLayoutProperty("aramiro_nz_basemap", "visibility", "visible");
  map.setLayoutProperty("mekong_vn_basemap", "visibility", "visible");
}

function getTilesForCurrentBounds(map) {
  console.log("getTilesForCurrentBounds");
  // var merc = new SphericalMercator({
  //   size: 256,
  //   // antimeridian: true
  // },

  // console.log();
  const bounds = map.getBounds();
  // [w, s, e, n]
  // doesnt' seem to work this way. Gotta see a example somewhere

  // -180,-85.05112877980659,180,85.0511287798066
  // console.log(
  //   "xyz",
  //   merc.xyz(
  //     [bounds._sw.lng, bounds._sw.lat, bounds._ne.lng, bounds._ne.lat],
  //     map.getZoom()
  //   )
  // );

  const l2tiles = tilebelt.getChildren(
    tilebelt.bboxToTile([
      bounds._sw.lng,
      bounds._sw.lat,
      bounds._ne.lng,
      bounds._ne.lat,
    ])
  );

  const l3tiles = l2tiles.reduce((acc, tile) => {
    const children = tilebelt.getChildren(tile);
    return acc.concat(children);
  }, []);

  // console.log("l3tiles", l3tiles);

  const isTileZoomPlus1 = l3tiles[0][2] < map.getZoom() ? true : false;

  const l4tiles = isTileZoomPlus1
    ? l3tiles.reduce((acc, tile) => {
        const children = tilebelt.getChildren(tile);
        return acc.concat(children);
      }, [])
    : l3tiles;

  // Two options -
  // 1. get point to tile (from the center/location of the map map.getcenter or center of the bbox) and then get siblings. That might be enough. If not one more round of siblings of all the corner tiles and then select distinct tiles of that. Get the tiles for those many.
  // 2. use the min max and get all the tiles between them

  // console.log("bbox", merc.bbox(8701, 4690, 14));
  const center = map.getCenter();

  const centerTile = tilebelt.pointToTile(
    center.lng,
    center.lat,
    Math.round(map.getZoom()) + 1
  );

  const parentTile = tilebelt.getParent(
    tilebelt.getParent(tilebelt.getParent(centerTile))
  );

  const childrenTiles = tilebelt.getChildren(parentTile);
  const childrenTilesl2 = childrenTiles.reduce((acc, tile) => {
    const children = tilebelt.getChildren(tile);
    return acc.concat(children);
  }, []);

  const childrenTilesl3 = childrenTilesl2.reduce((acc, tile) => {
    const children = tilebelt.getChildren(tile);
    return acc.concat(children);
  }, []);

  // If the getbounds was working could have used the l3/l4 tiles. Since that doesn't seem to be working properly, using the center tile and then getting parents of that and then getting children of that.
  return childrenTilesl3;
}

function addPlaceLabelLayers(map, mapPlaceLabelLayerList) {
  mapPlaceLabelLayerList.forEach((mapPlaceLabel) => {
    map.addLayer(mapPlaceLabel);
  });
}

function removePlaceLabelLayers(map) {
  if (map.getLayer("admin-1-boundary-bg")) {
    map.removeLayer("admin-1-boundary-bg");
    map.removeLayer("admin-0-boundary-bg");
    map.removeLayer("admin-1-boundary");
    map.removeLayer("admin-0-boundary");
    map.removeLayer("admin-0-boundary-disputed");
    map.removeLayer("settlement-subdivision-label");
    map.removeLayer("settlement-major-label");
    map.removeLayer("settlement-minor-label");
    map.removeLayer("state-label");
    map.removeLayer("country-label");
  }
}

export {
  addBaseMapLayers,
  getTilesForCurrentBounds,
  addPlaceLabelLayers,
  removePlaceLabelLayers,
  turnOffBaseMapLayers,
  turnOnBaseMapLayers,
};
