import {
  Button,
  FilledInput,
  makeStyles,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { store } from "../contexts/store";
import {
  setFeature,
  setMode,
  setPoint,
  setLocation,
  dispatch,
} from "../contexts/actions";
import { domain, api_version } from "../config";

import turf from "turf";

import countryCodes from "../util/countryCodes.json";
import UrlDisplay from "./UrlDisplay";
import ResponseDisplay from "./ResponseDisplay";
import InputField from "./InputField";
import DRCoverageTileDisplay from "./DRCoverageTileDisplay";

const useStyles = makeStyles({
  info: {
    padding: "24px 20px",
    boxSizing: "border-box",
    color: "#949EA7",
    backgroundColor: "#fff",
    marginBottom: 8,
    "& h3": {
      lineHeight: "24px",
      marginBottom: 8,
      color: "#1D2023",
    },
    "& h4": {
      color: "#1D2023",
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 600,
      margin: 0,
    },
    "& h6": {
      color: "#1D2023",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
      margin: 0,
    },
  },
  iconRoot: {
    fill: "none",
  },

  flex: {
    display: "flex",
  },
  flex1: {
    flex: 1,
  },
  rightContainer: {
    flex: 1,
    wordWrap: "anywhere",
    color: "#1D2023",
    "& h6": {
      marginBottom: 16,
    },
  },
  responseContainer: {
    display: "flex",
    marginBottom: 16,
    fontWeight: 700,
  },
  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: "20px",
  },

  inputRoot: {
    borderRadius: 4,
  },
  generateResp: {
    marginTop: 16,
    minHeight: 48,
    borderRadius: 4,
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 16,
  },
  url: {
    color: "#EA891D",
  },
  textFields: {
    display: "flex",
    marginTop: 12,
    "@media (max-width: 600px)": {
      flexWrap: "wrap",
      "& >div": {
        marginRight: "0px !important",
        marginBottom: 8,
      },
    },
  },
  tabsRoot: {
    backgroundColor: "#fff",
    borderRadius: 8,
    minHeight: 36,
    // margin: "0px 16px",
    color: "#1D2023",
    border: "1px solid #01B075",
    marginTop: 12,
    flexWrap: "wrap",
    maxWidth: "100%",
  },
  tab: {
    paddingBottom: 6,
    paddingTop: 6,
    minHeight: 36,
    minWidth: 120,
  },
  tabSelected: {
    backgroundColor: "#01B075",
    color: "#fff",
  },
  tabWrapper: {
    // paddingTop: 6,
    // paddingBottom: 6,
  },
  tabIndicator: {
    backgroundColor: "#fff",
    height: 0,
  },
  listItemIconRoot: {
    minWidth: 28,
    marginTop: 6,
  },
  listItemText: {
    fontWeight: 600,
    color: "#1D2023",
  },
  inDevMarker: {
    fill: "#EA891D",
  },
  showAllButton: {
    fontWeight: "600",
    marginBottom: "1em",
  },
  listItemRoot: {
    alignItems: "start",
    cursor: "pointer",
  },
  availableTab: {
    minWidth: 190,
  },
  listRoot: {
    minHeight: 120,
  },

  mgrsTitle: {
    marginLeft: 16,
    marginTop: 8,
    fontWeight: 600,
    fontSize: 14,
    marginBottom: -8,
    color: "#1D2023",
  },
  countryButton: {
    marginTop: 8,
    marginRight: 8,
  },
  countryButtonActive: {
    borderColor: "#01B075",
  },
});

const LocationListItem = ({ feature }) => {
  const classes = useStyles();
  const location =
    feature.geometry.type === "Point"
      ? feature.geometry.coordinates
      : turf.center(feature.geometry).geometry.coordinates;

  const { dispatch } = useContext(store);

  const setMapLocation = (location) => {
    dispatch({
      type: setLocation,
      payload: {
        location: { center: location },
      },
    });
  };
  const countryLowerCase = feature.properties.country.toLowerCase();

  return (
    <ListItem
      className={classes.listItemRoot}
      onClick={() => {
        setMapLocation(location);
      }}
    >
      <ListItemIcon className={classes.listItemIconRoot}>
        <img
          src={`https://flagcdn.com/20x15/${countryLowerCase}.png`}
          width="18"
          height="auto"
          alt={feature.properties.country}
        ></img>
      </ListItemIcon>
      <div>
        <ListItemText
          primary={feature.properties.mgrs_tile}
          classes={{ root: classes.listItemText }}
          disableTypography
        />
        <ListItemText
          secondary={
            feature.properties.area &&
            feature.properties.area.toLocaleString() + " ha"
          }
        ></ListItemText>
      </div>
    </ListItem>
  );
};

const LocationList = ({ list }) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  return (
    <div>
      <List className={classes.listRoot}>
        {(list.length <= 4 || showAll) &&
          list.map((feature) => {
            return <DRCoverageTileDisplay feature={feature} />;
          })}
        {list.length > 4 &&
          !showAll &&
          list.slice(0, 4).map((feature) => {
            return <DRCoverageTileDisplay feature={feature} />;
          })}
      </List>
      {list.length > 4 && !showAll && (
        <>
          <Button
            onClick={() => {
              setShowAll(true);
            }}
            className={classes.showAllButton}
            variant="outlined"
            color="primary"
            fullWidth
          >
            View all {list.length}
          </Button>
        </>
      )}
    </div>
  );
};

const CountryList = ({ list, setSelectedCountry, selectedCountry }) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  return (
    <div>
      <List>
        <Button
          onClick={() => {
            setSelectedCountry();
          }}
          variant="outlined"
          className={classes.countryButton}
          key="AllRegions"
        >
          All Regions
        </Button>
        {(list.length <= 6 || showAll) &&
          list.map((country) => {
            const countryLowerCase = country.toLowerCase();
            return (
              <Button
                onClick={() => {
                  setSelectedCountry(country);
                }}
                variant="outlined"
                className={[
                  classes.countryButton,
                  selectedCountry === country
                    ? classes.countryButtonActive
                    : "",
                ].join(" ")}
                key={country}
              >
                <img
                  src={`https://flagcdn.com/20x15/${countryLowerCase}.png`}
                  width="18"
                  height="auto"
                  alt={country}
                  className="mr-2"
                ></img>
                {Array.isArray(countryCodes[country])
                  ? countryCodes[country][1]
                  : countryCodes[country]}
              </Button>
            );
          })}
        {list.length > 6 &&
          !showAll &&
          list.slice(0, 6).map((country) => {
            return (
              <Button
                onClick={() => {
                  setSelectedCountry(country);
                }}
                variant="outlined"
                className={[
                  classes.countryButton,
                  selectedCountry === country
                    ? classes.countryButtonActive
                    : "",
                ].join(" ")}
                key={country}
              >
                <img
                  src={`https://flagcdn.com/20x15/${country.toLowerCase()}.png`}
                  width="18"
                  height="auto"
                  alt={country}
                  className="mr-2"
                ></img>
                {Array.isArray(countryCodes[country])
                  ? countryCodes[country][1]
                  : countryCodes[country]}
              </Button>
            );
          })}
        {list.length > 6 && !showAll && (
          <>
            <Button
              onClick={() => {
                setShowAll(true);
              }}
              className={classes.countryButton}
              variant="outlined"
              key="showAll"
            >
              {list.length} Others
            </Button>
          </>
        )}
      </List>
    </div>
  );
};

const DRCoverageDrawer = (props) => {
  const classes = useStyles();

  const {
    state: { mode: mapDrawMode },
    dispatch,
  } = useContext(store);

  const baseUrl = `${domain}/${api_version}`;

  const [responseJson, setResponseJson] = useState();
  const [responseStatus, setResponseStatus] = useState();
  const [requestUrl, setRequestUrl] = useState();
  const defaultToken = "a0731a8c-5259-4c68-af3a-7ad4f6d53faa";
  const [token, setToken] = useState(defaultToken);
  const [countryList, setCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [mgrsTiles, setMgrsTiles] = useState();
  // const [bBoxPoints, setBBoxPoints] = useState([-180, -90, 180, 90]);
  const [bbox, setBBOX] = useState("-180, -90, 180, 90");

  const handleClick = () => {
    const fetchUrl = `${baseUrl}/dr-imagery?token=${token}${
      mgrsTiles ? `&mgrs_tile=${mgrsTiles}` : ""
    }${bbox ? "&bbox=" + bbox : ""}`;
    setRequestUrl(fetchUrl);

    fetch(fetchUrl)
      .then((response) => {
        setResponseStatus(response.status);
        return response.json();
      })
      .then((data) => {
        setResponseJson(data);
      });
  };

  useEffect(() => {
    if (responseJson) {
      dispatch({
        type: setFeature,
        payload: {
          feature: responseJson,
        },
      });
      const uniqueCountryCodes =
        responseJson &&
        responseJson.features
          .map(({ properties }) => properties.country)
          .filter((item, i, ar) => ar.indexOf(item) === i);
      setCountryList(uniqueCountryCodes.filter((country) => country !== null));
      setSelectedCountry(uniqueCountryCodes[0]);
    }
  }, [responseJson]);

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h4>/dr-imagery</h4>
        <p>
          Returns polygons for the areas where Deeply resolved satellite imagery
          is available
        </p>
        <h3>Parameters</h3>
        <InputField
          label="Client Token provided by Digifarm and EarthDaily Analytics"
          defaultValue=""
          onChange={(e) => {
            setToken(e.target.value);
          }}
          value={token}
        ></InputField>
      </div>
      <div className={classes.info}>
        <InputField
          label="mgrs_tiles"
          value={mgrsTiles}
          onChange={(e) => {
            setMgrsTiles(e.target.value);
          }}
        ></InputField>

        <div className={classes.textFields}>
          <InputField
            label="bbox string in format of minLongitude,minLatitude,maxLongitude,maxLatitude"
            value={bbox}
            onChange={(e) => setBBOX(e.target.value)}
          />
        </div>

        <Button
          variant={"contained"}
          color="primary"
          onClick={handleClick}
          fullWidth={true}
          className={classes.generateResp}
        >
          Generate Response
        </Button>
      </div>

      {responseStatus && (
        <div className={classes.info}>
          {responseJson && (
            <>
              <h4 className={classes.mgrsTitle}>MGRS tiles:</h4>
              <CountryList
                list={countryList}
                setSelectedCountry={setSelectedCountry}
                selectedCountry={selectedCountry}
              />

              <LocationList
                list={
                  selectedCountry
                    ? responseJson.features.filter(
                        (feature) =>
                          feature.properties.country === selectedCountry
                      )
                    : responseJson.features
                }
              />
            </>
          )}
          <UrlDisplay requestUrl={requestUrl} />
          <div className={classes.responseContainer}>
            <div className={classes.responseCode}>{responseStatus}</div>
            <div className={classes.rightContainer}>
              <h6>
                {responseStatus === 200 && "Successfully fetched"}{" "}
                {responseStatus === 404 && "Field not found at the location"}
              </h6>
              <ResponseDisplay
                responseJson={responseJson}
                responseStatus={responseStatus}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DRCoverageDrawer;
