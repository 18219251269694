import { makeStyles } from "@material-ui/core";
import { useState, useContext } from "react";
import turf from "turf";
import { store } from "../contexts/store";
import { setLocation } from "../contexts/actions";

const useStyles = makeStyles({
  flex: {
    display: "flex",
  },
  tile: {
    fontWeight: 600,
    color: "#1D2023",
    minWidth: 74,
  },
  year: {
    minWidth: 46,
  },
  dates: {
    wordWrap: "break-word",
    maxWidth: "100%",
    flex: 1,
    marginBottom: 10,
  },
  tileContainer: {
    display: "flex",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const months = {
  "01": "Jan",
  "02": "Feb",
  "03": "Mar",
  "04": "Apr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Aug",
  "09": "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const DRCoverageTileDisplay = (props) => {
  const classes = useStyles();
  const { feature } = props;
  const { properties } = feature;
  const [years, setYears] = useState([]);

  const location =
    feature.geometry.type === "Point"
      ? feature.geometry.coordinates
      : turf.center(feature.geometry).geometry.coordinates;

  const { dispatch } = useContext(store);

  const setMapLocation = (location) => {
    dispatch({
      type: setLocation,
      payload: {
        location: { center: location },
      },
    });
  };

  // run filter on the available dates to extract the years.
  // Then on each of them
  //   console.log(properties);
  useState(() => {
    // console.log(properties);
    properties &&
      properties.availableDates.length &&
      setYears(
        properties.availableDates
          .map(({ source_date }) => source_date.substring(0, 4))
          .filter((item, i, ar) => ar.indexOf(item) === i)
      );
  }, []);

  {
    /* Need to show the new response. 
            map over the features. 
            tile name is in properties. 
            availableDates array contains source_date

            Component for each tile
            Show the tile name, 
            Then map over the years , 
            then show the dates - in a user readable format.
            - collapse 

            Filling the details of the date and tile location zoom in XYZ api
          */
  }

  return (
    <div
      className={classes.tileContainer}
      onClick={() => {
        setMapLocation(location);
      }}
    >
      <div className={classes.tile}>{properties.mgrs_tile} : </div>
      <div>
        {years.map((year) => {
          return (
            <div className={classes.flex}>
              <div className={classes.year}>{year}:</div>
              <div className={classes.dates}>
                {properties.availableDates
                  .filter(
                    ({ source_date }, i) => source_date.substring(0, 4) === year
                  )
                  .map(
                    ({ source_date }) =>
                      months[source_date.substring(4, 6)] +
                      " " +
                      source_date.substring(6, 8)
                  )
                  .join(", ")}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DRCoverageTileDisplay;
