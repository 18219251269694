import {
  Button,
  FilledInput,
  makeStyles,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { store } from "../contexts/store";
import { setFeature, setMode, setPoint } from "../contexts/actions";
import debounce from "lodash.debounce";

import { domain, api_version } from "../config";
import Disclaimer from "./Disclaimer";
import ResponseDisplay from "./ResponseDisplay";
import UrlDisplay from "./UrlDisplay";
import InputField from "./InputField";

const useStyles = makeStyles({
  info: {
    padding: "24px 20px",
    boxSizing: "border-box",
    color: "#949EA7",
    backgroundColor: "#fff",
    marginBottom: 8,
    "& h3": {
      lineHeight: "24px",
      marginBottom: 8,
      color: "#1D2023",
    },
    "& h4": {
      color: "#1D2023",
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 600,
      margin: 0,
    },
    "& h6": {
      color: "#1D2023",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
      margin: 0,
    },
  },
  iconRoot: {
    fill: "none",
  },

  mt8: {
    marginTop: 8,
  },

  discoverButton: {
    backgroundColor: "#01B075",
    color: "#fff",
    borderRadius: 4,
    textTransform: "capitalize",
    lineHeight: "28px",
    fontWeight: 600,
    padding: "6px 24px",
    marginTop: 24,
  },
  email: {
    color: "#01B075",
    fontSize: 16,
    fontWeight: 600,
    textDecoration: "underline",
  },
  getInTouch: {
    color: "#1D2023",
    marginBottom: 4,
    lineHeight: "24px",
    fontSize: 16,
  },
  flex: {
    display: "flex",
  },
  flex1: {
    flex: 1,
  },
  rightContainer: {
    flex: 1,
    wordWrap: "anywhere",
    color: "#1D2023",
    "& h6": {
      marginBottom: 16,
    },
  },
  responseContainer: {
    display: "flex",
    marginBottom: 16,
    fontWeight: 700,
    marginTop: 16,
  },
  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: "20px",
  },
  inputRoot: {
    borderRadius: 4,
  },
  generateResp: {
    marginTop: 16,
    minHeight: 48,
    borderRadius: 4,
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 16,
  },
  textFields: {
    display: "flex",
    marginTop: 12,
    "@media (max-width: 600px)": {
      flexWrap: "wrap",
      "& >div": {
        marginRight: "0px !important",
        marginBottom: 8,
      },
    },
  },
  radioGroupRoot: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
  },
  typeOfBiling: {
    fontSize: 16,
    marginRight: 32,
    color: "#1D2023",
    width: 118,
  },
});

const PointDrawer = (props) => {
  const classes = useStyles();

  const {
    state: { mode: mapDrawMode, point },
    dispatch,
  } = useContext(store);

  const [coordinates, setCoordinates] = useState([]);

  useEffect(() => {
    let lng = point && point.length && point[0];
    let lat = point && point.length && point[1];
    lng && setCoordinates([lng, lat]);
  }, [point]);

  useEffect(() => {
    const debouncedSave = debounce(
      () =>
        dispatch({
          type: setPoint,
          payload: {
            point: coordinates,
          },
        }),
      100
    );

    if (point && point.length) {
      if (point[0] != coordinates[0] || point[1] != coordinates[1]) {
        debouncedSave();
      }
    }
  }, [coordinates]);

  const defaultToken = "a0731a8c-5259-4c68-af3a-7ad4f6d53faa";
  const [token, setToken] = useState(defaultToken);
  const baseUrl = `${domain}/${api_version}`;
  // "https://2aqfsaq392.execute-api.eu-central-1.amazonaws.com/production";
  // "https://api.digifarm.io/v1";

  const [responseJson, setResponseJson] = useState();
  const [responseStatus, setResponseStatus] = useState();
  const [requestUrl, setRequestUrl] = useState();
  const [simplifiedGeometry, setSimplyfiedGeometry] = useState("false");
  const [version, setVersion] = useState("latest");

  const handleClick = () => {
    const fetchUrl =
      baseUrl +
      "/delineated-fields/location" +
      "?token=" +
      token +
      "&location=" +
      coordinates.join(",") +
      "&data_version=" +
      version +
      "&simplified_geometry=" +
      simplifiedGeometry;
    setRequestUrl(fetchUrl);

    fetch(fetchUrl)
      .then((response) => {
        setResponseStatus(response.status);
        return response.json();
      })
      .then((data) => {
        setResponseJson(data);
      });
  };

  useEffect(() => {
    if (responseJson) {
      dispatch({
        type: setFeature,
        payload: {
          feature: responseJson,
        },
      });
    }
  }, [responseJson]);

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h4>/delineated-fields/location</h4>
        <p>Returns the field for the given location</p>
        <h3>Parameters</h3>
        <InputField
          label="Client Token provided by Digifarm and EarthDaily Analytics"
          defaultValue=""
          value={token}
          onChange={(e) => {
            setToken(e.target.value);
          }}
        ></InputField>
        <div className={classes.radioGroupRoot}>
          <div className={classes.typeOfBiling}>Data Version :</div>
          <RadioGroup
            row
            aria-label="data version"
            name="data version"
            value={"1"}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Latest"
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
        <div className={classes.radioGroupRoot}>
          <div className={classes.typeOfBiling}>Simplified Geometry :</div>
          <RadioGroup
            row
            aria-label="simplified geometry"
            name="simplified_geometry"
            value={simplifiedGeometry}
            onChange={(e) => {
              setSimplyfiedGeometry(e.target.value);
            }}
          >
            <FormControlLabel
              value={"false"}
              control={<Radio color="primary" />}
              label="False"
              labelPlacement="end"
            />
            <FormControlLabel
              value={"true"}
              control={<Radio color="primary" />}
              label="True"
              labelPlacement="end"
            />
          </RadioGroup>
        </div>
        <div className={classes.textFields}>
          <InputField
            label="Location in format of longitude"
            defaultValue=""
            value={coordinates && coordinates[0]}
            className="mr-12"
            onChange={(e) => {
              setCoordinates([e.target.value, coordinates[1]]);
            }}
          ></InputField>
          <InputField
            label="Location in format of latitude"
            defaultValue=""
            value={coordinates && coordinates[1]}
            onChange={(e) => {
              setCoordinates([coordinates[0], e.target.value]);
            }}
          ></InputField>
        </div>
        <div className={classes.textFields}>
          <InputField
            label="Data Version"
            defaultValue=""
            value={version}
            className="mr-12"
            onChange={(e) => {
              setVersion(e.target.value);
            }}
          ></InputField>
          <div></div>
        </div>

        <Button
          variant={coordinates && coordinates.length ? "contained" : "outlined"}
          color="primary"
          onClick={handleClick}
          fullWidth={true}
          className={classes.generateResp}
        >
          Generate Response
        </Button>
      </div>

      {responseStatus && (
        <div className={classes.info}>
          <h3>Responses</h3>
          <UrlDisplay requestUrl={requestUrl} />
          <div className={classes.responseContainer}>
            <div className={classes.responseCode}>{responseStatus}</div>
            <div className={classes.rightContainer}>
              <h6>
                {responseStatus === 200 && "Successfully fetched"}{" "}
                {responseStatus === 404 && "Field not found at the location"}
              </h6>
              <ResponseDisplay
                responseJson={responseJson}
                responseStatus={responseStatus}
              />
              {!responseJson && (
                <div className={classes.responseContainer}>
                  Please check the coverage API to see if we have data for the
                  region you are querying. The test token allows you to query to
                  the DR API for only a sample area of each region. If you wish
                  to query outside the sample region shown get in touch with us
                  at hello@digifarm.io
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PointDrawer;
