import {
  Button,
  FilledInput,
  makeStyles,
  Tabs,
  TextField,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { store } from "../contexts/store";
import {
  setFeature,
  setMode,
  setPoint,
  setLocation,
  dispatch,
} from "../contexts/actions";
import { domain, api_version } from "../config";

import copyToClipboard from "../util/copyToClipboard";
import { saveAs } from "file-saver";
import turf from "turf";

import Disclaimer from "./Disclaimer";
import InputField from "./InputField";
import UrlDisplay from "./UrlDisplay";
import ResponseDisplay from "./ResponseDisplay";

const useStyles = makeStyles({
  info: {
    padding: "24px 20px",
    boxSizing: "border-box",
    color: "#949EA7",
    backgroundColor: "#fff",
    marginBottom: 8,
    "& h3": {
      lineHeight: "24px",
      marginBottom: 8,
      color: "#1D2023",
    },
    "& h4": {
      color: "#1D2023",
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 600,
      margin: 0,
    },
    "& h6": {
      color: "#1D2023",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
      margin: 0,
    },
  },
  iconRoot: {
    fill: "none",
  },

  mt8: {
    marginTop: 8,
  },

  getInTouch: {
    color: "#1D2023",
    marginBottom: 4,
    lineHeight: "24px",
    fontSize: 16,
  },
  flex: {
    display: "flex",
  },
  flex1: {
    flex: 1,
  },
  rightContainer: {
    flex: 1,
    wordWrap: "anywhere",
    color: "#1D2023",
    "& h6": {
      marginBottom: 16,
    },
  },
  responseContainer: {
    display: "flex",
    marginBottom: 16,
    fontWeight: 700,
  },
  responseCode: {
    marginRight: 24,
    width: 58,
    lineHeight: "20px",
  },

  generateResp: {
    marginTop: 16,
    minHeight: 48,
    borderRadius: 4,
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 16,
  },

  textFields: {
    display: "flex",
    marginTop: 12,
    "@media (max-width: 600px)": {
      flexWrap: "wrap",
      "& >div": {
        marginRight: "0px !important",
        marginBottom: 8,
      },
    },
  },
  tabsRoot: {
    backgroundColor: "#fff",
    borderRadius: 8,
    minHeight: 36,
    // margin: "0px 16px",
    color: "#1D2023",
    border: "1px solid #01B075",
    marginTop: 12,
  },
  tab: {
    paddingBottom: 6,
    paddingTop: 6,
    minHeight: 36,
    minWidth: 120,
  },
  tabSelected: {
    backgroundColor: "#01B075",
    color: "#fff",
  },
  tabWrapper: {
    // paddingTop: 6,
    // paddingBottom: 6,
  },
  tabIndicator: {
    backgroundColor: "#fff",
    height: 0,
  },
  listItemIconRoot: {
    minWidth: 32,
    marginTop: 6,
  },
  listItemText: {
    fontWeight: 600,
    color: "#1D2023",
  },
  inDevMarker: {
    fill: "#EA891D",
  },
  showAllButton: {
    fontWeight: "600",
    marginBottom: "1em",
  },
  listItemRoot: {
    alignItems: "start",
    cursor: "pointer",
  },
  availableTab: {
    minWidth: 190,
  },
  listRoot: {
    minHeight: 180,
  },
  radioGroupRoot: {
    display: "flex",
    alignItems: "center",
    marginTop: 8,
  },
  typeOfBiling: {
    fontSize: 16,
    marginRight: 32,
    color: "#1D2023",
    width: 118,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// dispatch({
//   type: setPoint,
//   payload: {
//     point: e.features[0].geometry.coordinates,
//   },
// });

const LocationListItem = ({ feature }) => {
  const classes = useStyles();
  const location =
    feature.geometry.type === "Point"
      ? feature.geometry.coordinates
      : turf.center(feature.geometry).geometry.coordinates;

  const { dispatch } = useContext(store);

  const setMapLocation = (location) => {
    dispatch({
      type: setLocation,
      payload: {
        location: { center: location, zoom: 6 },
      },
    });
  };

  return (
    <ListItem
      className={classes.listItemRoot}
      onClick={function () {
        setMapLocation(location);
      }}
    >
      <ListItemIcon className={classes.listItemIconRoot}>
        <img
          src={`https://flagcdn.com/20x15/${feature.properties.country_code.toLowerCase()}.png`}
          width="18"
          height="auto"
          className="ml-2"
          alt={feature.properties.country}
        ></img>
      </ListItemIcon>
      <div>
        <ListItemText
          primary={feature.name}
          classes={{ root: classes.listItemText }}
          disableTypography
        />
        <ListItemText
          secondary={
            feature.properties.area &&
            feature.properties.area.toLocaleString() + " ha"
          }
        ></ListItemText>
      </div>
    </ListItem>
  );
};

const LocationList = ({ list }) => {
  const classes = useStyles();
  const [showAll, setShowAll] = useState(false);

  return (
    <div>
      <List className={classes.listRoot}>
        {(list.length <= 4 || showAll) &&
          list.map((feature) => {
            return <LocationListItem feature={feature} />;
          })}
        {list.length > 4 &&
          !showAll &&
          list.slice(0, 4).map((feature) => {
            return <LocationListItem feature={feature} />;
          })}
      </List>
      {list.length > 4 && !showAll && (
        <>
          <Button
            onClick={() => {
              setShowAll(true);
            }}
            className={classes.showAllButton}
            variant="outlined"
            color="primary"
            fullWidth
          >
            View all {list.length}
          </Button>
        </>
      )}
    </div>
  );
};

const TimeSeriesCoverage = (props) => {
  const classes = useStyles();

  const {
    state: { mode: mapDrawMode },
    dispatch,
  } = useContext(store);

  const baseUrl = `${domain}/${api_version}`;
  // "https://2aqfsaq392.execute-api.eu-central-1.amazonaws.com/production";
  // "https://api.digifarm.io/v1";

  const [responseJson, setResponseJson] = useState();
  const [responseStatus, setResponseStatus] = useState();
  const [requestUrl, setRequestUrl] = useState();
  const defaultToken = "a0731a8c-5259-4c68-af3a-7ad4f6d53faa";
  const [token, setToken] = useState(defaultToken);
  const [tabValue, setTabValue] = useState(0);

  const handleClick = () => {
    const fetchUrl =
      "https://api.digifarm.io/development/indices-timeseries/coverage" +
      "?token=" +
      token +
      "&data_version=latest";
    setRequestUrl(fetchUrl);

    fetch(fetchUrl)
      .then((response) => {
        setResponseStatus(response.status);
        return response.json();
      })
      .then((data) => {
        setResponseJson(data);
      });
  };

  useEffect(() => {
    if (responseJson) {
      dispatch({
        type: setFeature,
        payload: {
          feature: responseJson,
        },
      });
    }
  }, [responseJson]);

  const copyContent = () => {
    copyToClipboard(JSON.stringify(responseJson));
  };
  const downloadGeoJSON = () => {
    const blob = new Blob([JSON.stringify(responseJson, null, 2)], {
      type: "application/json",
    });
    saveAs(blob, "coverage-area.json");
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  return (
    <div className={classes.root}>
      <div className={classes.info}>
        <h4>/delineated-areas</h4>
        <p>
          Returns polygons for the areas where delineated fields are available
        </p>
        <h3>Parameters</h3>
        <InputField
          label="Client Token provided by Digifarm and EarthDaily Analytics"
          defaultValue=""
          onChange={(e) => {
            setToken(e.target.value);
          }}
          value={token}
        ></InputField>
        <div className={classes.radioGroupRoot}>
          <div className={classes.typeOfBiling}>Data Version :</div>
          <RadioGroup
            row
            aria-label="data version"
            name="data version"
            value={"1"}
          >
            <FormControlLabel
              value="1"
              control={<Radio color="primary" />}
              label="Latest"
              labelPlacement="end"
            />
          </RadioGroup>
        </div>

        <Button
          variant={"contained"}
          color="primary"
          onClick={handleClick}
          fullWidth={true}
          className={classes.generateResp}
        >
          Generate Response
        </Button>
      </div>

      {responseStatus && (
        <div className={classes.info}>
          <h3>Responses</h3>

          <UrlDisplay requestUrl={requestUrl} />
          <div className={classes.responseContainer}>
            <div className={classes.responseCode}>{responseStatus}</div>
            <div className={classes.rightContainer}>
              <h6>
                {responseStatus === 200 && "Successfully fetched"}{" "}
                {responseStatus === 404 && "Field not found at the location"}
              </h6>
              <ResponseDisplay
                responseJson={responseJson}
                responseStatus={responseStatus}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeSeriesCoverage;
