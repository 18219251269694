const setMode = "setMode";
const setPoint = "setPoint";
const setPolygon = "setPolygon";
const setFeature = "setFeature";
const setDrawMode = "setDrawMode";
const setTile = "setTile";
const setFieldId = "setFieldId";
const setLocation = "setLocation";
const setDataLayer = "setDataLayer";
const setToken = "setToken";
const setTiles = "setTiles";
const setAPI = "setAPI";
const setTileURL = "setTileURL";

export {
  setMode,
  setPoint,
  setPolygon,
  setFeature,
  setDrawMode,
  setTile,
  setFieldId,
  setLocation,
  setDataLayer,
  setToken,
  setTiles,
  setAPI,
  setTileURL,
};
