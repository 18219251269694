import { GlobeFinanceIcon } from "../icons";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  email: {
    color: "#01B075",
    fontWeight: 600,
    textDecoration: "underline",
    display: "inline",
  },
  disclaimer: {
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
    borderRadius: 8,
    padding: 12,
    marginTop: 24,
    display: "flex",
    color: "#1D2023",
    alignItems: "center",
  },
  disclaimerIconSection: {
    padding: 12,
    backgroundColor: "#FDF5EC",
    borderRadius: 8,
    marginRight: 8,
  },
  disclaimerIcon: {
    fill: "none",
  },
});
const Disclaimer = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.disclaimer}>
      <div className={classes.disclaimerIconSection}>
        <GlobeFinanceIcon className={classes.disclaimerIcon} />
      </div>
      <div>
        Our Sandbox API only lets you query boundaries within a small sample
        area of each region. If you’d like to query boundaries in any of our
        regions, get in touch with us at{" "}
        <a className={classes.email} href="mailto:hello@digifarm.io">
          hello@digifarm.io{" "}
        </a>
      </div>
    </div>
  );
};

export default Disclaimer;
