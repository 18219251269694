import { Drawer, makeStyles, Select, MenuItem } from "@material-ui/core";
import React, { useState, useEffect, useRef, useContext } from "react";
import { store } from "../contexts/store";
import { setAPI } from "../contexts/actions";
import PointDrawer from "./PointDrawer";
import BBoxDrawer from "./BBoxDrawer";
import FieldIdDrawer from "./FieldIdDrawer";
import CoverageDrawer from "./CoverageDrawer";
import DRCoverageDrawer from "./DRCoverageDrawer";
import DRTileDrawer from "./DRTileDrawer";
import DRBboxDrawer from "./DRBboxDrawer";
import TimeSeriesFieldIdDrawer from "./TimeSeriesFieldIdDrawer";
import TimeSeriesCoverage from "./TimeSeriesCoverage";
const useStyles = makeStyles({
  drawer: {
    width: 560,
    "@media (max-width: 600px)": {
      width: "unset",
      maxHeight: 300,
      overflowY: "scroll",
    },
  },
  root: {
    width: 560,
    "@media (max-width: 600px)": {
      width: "unset",
      maxHeight: 300,
    },
    backgroundColor: "#F8F9FA",
    fontSize: 14,
    "& h3": {
      fontSize: 20,
      lineHeight: 1.2,
      margin: 0,
    },
  },
  info: {
    padding: "24px 20px",
    boxSizing: "border-box",
    color: "#949EA7",
    backgroundColor: "#fff",
    marginBottom: 8,
    "& h3": {
      lineHeight: "24px",
      marginBottom: 8,
      color: "#1D2023",
    },
    "& h4": {
      color: "#1D2023",
      fontSize: 18,
      lineHeight: "24px",
      fontWeight: 600,
      margin: 0,
    },
    "& h6": {
      color: "#1D2023",
      fontSize: 14,
      lineHeight: "20px",
      fontWeight: 700,
      margin: 0,
    },
  },

  flex: {
    display: "flex",
  },
  flex1: {
    flex: 1,
  },
  inputRoot: {
    borderRadius: 4,
  },
  dropdownContainer: {
    backgroundColor: "#fff",
  },
  select: {
    width: "100%",
    fontSize: 20,
    lineHeight: "24px",
    fontWeight: 700,
    paddingTop: "1em",
    paddingBottom: "1em",
    paddingLeft: "1em",
  },
  selectInputRoot: {
    "&::before": {
      borderBottomColor: "#EEEFF1",
    },
  },
});

const RightDrawer = (props) => {
  const classes = useStyles();
  const { open, onClose } = props;

  const {
    state: { mode, drawMode: mapDrawMode, dataLayer },
    dispatch,
  } = useContext(store);

  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Drawer
      anchor={windowSize < 600 ? "bottom" : "right"}
      open={open}
      onClose={onClose}
      ModalProps={{ BackdropProps: { invisible: true } }}
      variant={"persistent"}
      className={open ? "right-side-drawer open" : "right-side-drawer"}
    >
      <div className={classes.root}>
        <div className={classes.dropdownContainer}>
          <Select
            id="right-drawer-mode-select"
            classes={{
              root: classes.select,
              icon: classes.selectIcon,
              outlined: classes.outlined,
            }}
            value={dataLayer.api}
            onChange={(event) => {
              console.log(event.target.value, "setting select");
              dispatch({
                type: setAPI,
                payload: {
                  api: event.target.value,
                  drawMode:
                    event.target.value === "lowResBBOX" ||
                    event.target.value === "bbox"
                      ? "draw_rectangle"
                      : event.target.value === "point"
                      ? "draw_point"
                      : "",
                },
              });
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            fullWidth={true}
          >
            {/* What to do when the api is lowResTile */}
            {dataLayer.type === "delineatedFields" && (
              <MenuItem value={"lowResBBOX"}>
                Query by Field ID (lowResBBOX){" "}
              </MenuItem>
            )}
            {dataLayer.type === "delineatedFields" && (
              <MenuItem value={"lowResTile"}>
                Query by Field ID (lowResTile){" "}
              </MenuItem>
            )}
            {dataLayer.type === "delineatedFields" && (
              <MenuItem value={"point"}>Query by Point</MenuItem>
            )}
            {dataLayer.type === "delineatedFields" && (
              <MenuItem value={"bbox"}>Query by Bounding box</MenuItem>
            )}
            {/* {dataLayer.type === "delineatedFields" && ()} */}

            {dataLayer.type === "deepResImages" && (
              <MenuItem value={"xyz"}>Query by Tile URL</MenuItem>
            )}
            {dataLayer.type === "deepResImages" && (
              <MenuItem value={"bbox"}>Query by BBOX</MenuItem>
            )}
            {dataLayer.type === "timeSeries" && (
              <MenuItem value={"lowResBBOX"}>
                Query by Field ID (lowResBBOX){" "}
              </MenuItem>
            )}
            {dataLayer.type === "timeSeries" && (
              <MenuItem value={"lowResTile"}>
                Query by Field ID (lowResTile){" "}
              </MenuItem>
            )}
            <MenuItem value={"coverage"}>Query Coverage area</MenuItem>
          </Select>
          {/* </FormControl> */}
        </div>
        {dataLayer.type === "delineatedFields" ? (
          <>
            {dataLayer.api === "point" && <PointDrawer />}
            {dataLayer.api === "bbox" && <BBoxDrawer />}
            {(dataLayer.api === "lowResBBOX" ||
              dataLayer.api === "lowResTile") && <FieldIdDrawer />}
            {dataLayer.api === "coverage" && <CoverageDrawer />}
          </>
        ) : dataLayer.type === "deepResImages" ? (
          <>
            {dataLayer.api === "xyz" && <DRTileDrawer />}
            {dataLayer.api === "bbox" && <DRBboxDrawer />}
            {dataLayer.api === "coverage" && <DRCoverageDrawer />}
          </>
        ) : (
          <>
            {(dataLayer.api === "lowResBBOX" ||
              dataLayer.api === "lowResTile") && <TimeSeriesFieldIdDrawer />}
            {dataLayer.api === "coverage" && <TimeSeriesCoverage />}
          </>
        )}
      </div>
    </Drawer>
  );
};

export default RightDrawer;
