import { Button, Drawer, makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { GlobeIcon, PointerIcon, BBoxIcon, CoverageIcon } from "../icons";

const useStyles = makeStyles({
  root: {
    width: 380,
    backgroundColor: "#F8F9FA",
    fontSize: 14,
  },
  infoRoot: {
    padding: "24px 20px",
    boxSizing: "border-box",
    color: "#949EA7",
    backgroundColor: "#fff",
    "& h3": {
      lineHeight: "24px",
      marginBottom: 8,
      color: "#1D2023",
      marginTop: 24,
    },
  },
  apiType: {
    display: "flex",
    margin: "24px 0",
  },
  iconRoot: {
    fill: "none",
    "& path": {
      stroke: "#01B075",
    },
  },
  apiInfo: {
    paddingLeft: 8,
    "& h4": {
      marginTop: 0,
      lineHeight: "24px",
      marginBottom: 8,
      color: "#1D2023",
    },
  },
  mt8: {
    marginTop: 8,
  },
  delinationTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: "#1D2023",
    marginBottom: 8,
  },
  discoverButton: {
    display: "inline-block",
    backgroundColor: "#01B075",
    color: "#fff",
    borderRadius: 4,
    textTransform: "capitalize",
    lineHeight: "28px",
    fontWeight: 600,
    padding: "6px 24px",
    marginTop: 24,
    textDecoration: "none",
  },
  email: {
    color: "#01B075",
    fontSize: 16,
    fontWeight: 600,
    textDecoration: "underline",
  },
  getInTouch: {
    color: "#1D2023",
    marginBottom: 4,
    lineHeight: "24px",
    fontSize: 16,
  },
  logo: {
    height: 36,
  },
  eda_logo: {
    height: 62,
    marginLeft: 24,
  },
  logoDiv: {
    display: "flex",
    alignItems: "center",
  },
});

const LeftDrawer = (props) => {
  const classes = useStyles();
  const { open, onClose } = props;
  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      ModalProps={{ BackdropProps: { invisible: true } }}
    >
      <div className={classes.root}>
        <div className={classes.infoRoot}>
          <div className={classes.logoDiv}>
            <a href="https://digifarm.io" target="_blank">
              <img src="/digifarm_logo.png" className={classes.logo}></img>
            </a>
            <a href="https://earthdaily.com" target="_blank">
              <img
                src="/EarthDaily_Logo.jpg"
                className={classes.eda_logo}
              ></img>
            </a>
          </div>
          <div></div>
          <h3 className={classes.delinationTitle}>3 types of API queries</h3>
          <div>
            We can serve our field boundaries data via API so you can integrate
            it withing your application.
          </div>
          <div className={classes.apiType}>
            <div>
              <GlobeIcon classes={{ root: classes.iconRoot }} />
            </div>
            <div className={classes.apiInfo}>
              <h4>By Field ID</h4>
              We serve low res (simplified) boundaries inside a bbox or as
              vector tiles, so they can be displayed on a map in your app. After
              click the app queries our API with ID of the clicked field. Our
              API returns the field boundary in high res.
            </div>
          </div>
          <div className={classes.apiType}>
            <div>
              <PointerIcon classes={{ root: classes.iconRoot }} />
            </div>
            <div className={classes.apiInfo}>
              <h4>By Point</h4>
              Click on a map in your app, it makes a query to our API with
              latitude / longitude of the point and our API returns field
              boundary geojson data around that point.
            </div>
          </div>
          <div className={classes.apiType}>
            <div>
              <BBoxIcon classes={{ root: classes.iconRoot }} />{" "}
            </div>
            <div className={classes.apiInfo}>
              <h4>By Bbox</h4>
              User draws a polygon on a map and our API returns all field
              boundaries in that bbox.
            </div>
          </div>
          <div className={classes.apiType}>
            <div>
              <CoverageIcon classes={{ root: classes.iconRoot }} />{" "}
            </div>
            <div className={classes.apiInfo}>
              <h4>Coverage</h4>
              Returns polygons representing the areas where we have data.
            </div>
          </div>
        </div>
        <div className={[classes.infoRoot, classes.mt8].join(" ")}>
          <div className={classes.delinationTitle}>
            Automatic Field Delineation Model
          </div>
          <div>
            We have developed state-of-the-art deep neural network model for
            Field Delineation (automatic detection of field boundaries and
            seeded acres).
          </div>
          <a
            className={classes.discoverButton}
            href="https://demo.digifarm.io"
            target="_blank"
          >
            Discover
          </a>
          <h4 className={classes.getInTouch}>
            For any questions please get in touch
          </h4>
          <div className={classes.email}>hello@digifarm.io </div>
        </div>
      </div>
    </Drawer>
  );
};

export default LeftDrawer;
