import MapboxDraw from "@mapbox/mapbox-gl-draw";
import hat from "hat";
const SinglePointMode = MapboxDraw.modes.draw_point;

SinglePointMode.onSetup = function (opts) {
  var state = {};
  state.count = opts.count || 0;
  var point = this.newFeature({
    type: "Feature",
    properties: {
      count: state.count,
    },
    geometry: {
      type: "Point",
      coordinates: [],
    },
  });
  this.addFeature(point);
  state.point = point;
  return state;
};

SinglePointMode.onClick = function (state, e) {
  this.deleteFeature(state.point.id, { silent: true });
  var point = this.newFeature({
    type: "Feature",
    properties: {
      count: state.count,
    },
    geometry: {
      type: "Point",
      coordinates: [e.lngLat.lng, e.lngLat.lat],
    },
  });
  state.point = point;
  this.addFeature(point); // puts the point on the map

  this.map.fire("draw.create", {
    features: [state.point.toGeoJSON()],
  });
};

SinglePointMode.onStop = function () {
  console.log("stopped");
};

SinglePointMode.toDisplayFeatures = function (state, geojson, display) {
  display(geojson);
};

export default SinglePointMode;
