import { makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles({
  inputRoot: {
    borderRadius: 4,
  },

  shrinkLabel: {
    fontSize: 14,
  },
});

const InputField = (props) => {
  const classes = useStyles();
  return (
    <TextField
      variant="filled"
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.inputRoot,
        },
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          shrink: classes.shrinkLabel,
        },
      }}
      fullWidth={true}
      {...props}
    ></TextField>
  );
};

export default InputField;
